import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../providers/ThemeProvider';
import { FaGithub, FaTwitter, FaTelegram, FaDiscord } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi';

const Footer = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    { icon: <FaGithub className="w-6 h-6" />, href: "https://github.com/bnb-chain" },
    { icon: <FaTwitter className="w-6 h-6" />, href: "https://twitter.com/bnb_chain" },
    { icon: <FaTelegram className="w-6 h-6" />, href: "https://t.me/bnbchain" },
    { icon: <FaDiscord className="w-6 h-6" />, href: "https://discord.com/invite/bnbchain" },
  ];

  const footerLinks = [
    { title: t('footer.documentation'), items: [
      { label: t('footer.whitepaper'), href: "https://github.com/bnb-chain/whitepaper/" },
      { label: t('footer.documentation'), href: "https://docs.bnbchain.org/" },
      { label: t('footer.github'), href: "https://github.com/bnb-chain" },
    ]},
    { title: t('footer.community'), items: [
      { label: t('footer.blog'), href: "https://www.binance.com/en/blog" },
      { label: t('footer.forum'), href: "https://forum.bnbchain.org/" },
      { label: t('footer.telegram'), href: "https://t.me/bnbchain" },
    ]},
    { title: t('footer.resources'), items: [
      { label: t('footer.staking'), href: "#stake" },
      { label: t('footer.statistics'), href: "#statistics" },
      { label: t('footer.faq'), href: "#faq" },
    ]},
  ];

  return (
    <footer className="bg-white dark:bg-gray-900 transition-colors duration-300">
      {/* Main Footer */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          {/* Logo Section */}
          <div className="md:col-span-2">
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/f/fc/Binance-coin-bnb-logo.png" 
              alt="BSC-USDT Staking Logo" 
              className="h-12 w-auto mb-4"
            />
            <p className="text-gray-600 dark:text-gray-400 mb-4 transition-colors duration-300">
              {t('footer.description')}
            </p>
            {/* Social Links */}
            <div className="flex space-x-4">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-bnb-yellow transition-colors duration-300"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Links Sections */}
          {footerLinks.map((section, index) => (
            <div key={index} className="md:col-span-1">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white transition-colors duration-300">
                {section.title}
              </h3>
              <ul className="space-y-2">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <a
                      href={item.href}
                      className="text-gray-600 dark:text-gray-400 hover:text-bnb-yellow dark:hover:text-bnb-yellow transition-colors duration-300"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-200 dark:border-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 dark:text-gray-400 text-sm transition-colors duration-300">
              © {currentYear} BSC-USDT Staking. {t('footer.rights')}
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a
                href="/privacy"
                className="text-gray-600 dark:text-gray-400 text-sm hover:text-bnb-yellow transition-colors duration-300"
              >
                {t('footer.privacy')}
              </a>
              <a
                href="/terms"
                className="text-gray-600 dark:text-gray-400 text-sm hover:text-bnb-yellow transition-colors duration-300"
              >
                {t('footer.terms')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;