import React, { useState, useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Moon, Sun, Menu, Globe, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../providers/LanguageProvider';
import { Link } from 'react-router-dom';

const Header = ({ toggleTheme, isDarkMode }) => {
  const { t } = useTranslation();
  const { currentLanguage, changeLanguage, languages } = useLanguage();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // Dil seçenekleri
  const languageOptions = [
    { code: 'tr', name: 'Türkçe', flag: '🇹🇷' },
    { code: 'en', name: 'English', flag: '🇬🇧' },
    { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'ru', name: 'Русский', flag: '🇷🇺' },
    { code: 'zh', name: '中文', flag: '🇨🇳' },
    { code: 'ja', name: '日本語', flag: '🇯🇵' },
    { code: 'pt-BR', name: 'Português', flag: '🇧🇷' },
    { code: 'az', name: 'Azərbaycan', flag: '🇦🇿' },
    { code: 'ar', name: 'العربية', flag: '🇸🇦' }
  ];

  // Navigation items with proper camelCase
  const navItems = [
    { id: 'home', label: 'Home' },
    { id: 'stake', label: 'Stake' },
     { id: 'statistics', label: 'Statistics' },
    { id: 'referral', label: 'Referrals' },
    { id: 'activeStakes', label: 'ActiveStakes' },
    { id: 'faq', label: 'FAQ' }
  ];
  

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showMobileMenu]);

  return (
    <header 
      className={`fixed top-0 w-full z-50 transition-all duration-500 ${
        isScrolled 
          ? 'py-3 bg-gray-900/95 backdrop-blur-lg shadow-lg' 
          : 'py-5 bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0 group">
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/f/fc/Binance-coin-bnb-logo.png" 
              alt="BNB Staking Logo" 
              className="h-8 md:h-10 transition-transform duration-300 group-hover:scale-105" 
            />
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            {navItems.map(({ id, label }) => (
              <a
                key={id}
                href={`#${id}`}
                className="relative text-[15px] font-medium text-bnb-yellow hover:text-blue-400 transition-colors duration-300 group"
              >
                {t(`nav.${id}`, label)}
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-bnb-yellow transition-all duration-300 group-hover:w-full" />
              </a>
            ))}
          </nav>

          {/* Right side items */}
          <div className="flex items-center space-x-6">
            {/* Language Selector */}
          
              <div className="absolute right-0 mt-2 w-48 bg-gray-900/95 backdrop-blur-lg rounded-xl shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 border border-gray-700/50">
                {languageOptions.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => changeLanguage(lang.code)}
                    className={`w-full px-4 py-3 text-left flex items-center space-x-3 hover:bg-white/5 transition-colors duration-300 first:rounded-t-xl last:rounded-b-xl ${
                      currentLanguage === lang.code ? 'text-bnb-yellow bg-white/5' : 'text-gray-200'
                    }`}
                  >
                    <span className="text-lg">{lang.flag}</span>
                    <span className="font-medium">{lang.name}</span>
                  </button>
                ))}
              </div>
            </div>


            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className="p-2 rounded-lg hover:bg-white/5 transition-colors duration-300"
              aria-label="Toggle theme"
            >
              {isDarkMode ? (
                <Sun className="w-5 h-5 text-bnb-yellow" />
              ) : (
                <Moon className="w-5 h-5 text-bnb-yellow" />
              )}
            </button>

         
            {/* Wallet Connect */}
            <ConnectButton.Custom>
              {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                if (!mounted) return null;

                if (!account || !chain) {
                  return (
                    <button
                      onClick={openConnectModal}
                      className="bg-blue-400 hover:bg-yellow-400 text-black font-semibold px-6 py-2.5 rounded-xl transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-bnb-yellow/20"
                    >
                      {t('wallet.connect')}
                    </button>
                  );
                }

                if (chain.unsupported) {
                  return (
                    <button
                      onClick={openChainModal}
                      className="bg-red-500 hover:bg-red-600 text-white font-semibold px-6 py-2.5 rounded-xl transition-all duration-300 hover:shadow-lg hover:shadow-red-500/20"
                    >
                      {t('wallet.wrongNetwork')}
                    </button>
                  );
                }

                return (
                  <div className="flex items-center gap-3">
                    <button
                      onClick={openChainModal}
                      className="flex items-center space-x-2 bg-blue-400 backdrop-blur-md px-4 py-2.5 rounded-xl hover:bg-yellow/10 transition-all duration-300"
                    >
                      {chain.hasIcon && (
                        <img
                          alt={chain.name ?? 'Chain icon'}
                          src={chain.iconUrl}
                          className="w-5 h-5"
                        />
                      )}
                    </button>

                    <button
                      onClick={openAccountModal}
                      className="bg-blue-400 backdrop-blur-md text-gray-200 px-4 py-2.5 rounded-xl hover:bg-white/10 transition-all duration-300 font-medium"
                    >
                      {account.displayName}
                    </button>
                  </div>
                );
              }}
            </ConnectButton.Custom>

            {/* Language Selector (now to the right of Wallet Connect) */}
            <div className="relative group">
              <button className="flex items-center space-x-2 text-bnb-yellow hover:text-bnb-yellow transition-colors duration-300 p-2 rounded-lg hover:bg-black/5">
                <Globe className="w-5 h-5" />
                <span className="text-base hidden md:inline-block">
                  {languageOptions.find(lang => lang.code === currentLanguage)?.flag}
                </span>
              </button>
              <div className="absolute right-0 mt-2 w-48 bg-gray-900/95 backdrop-blur-lg rounded-xl shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 border border-gray-700/50">
                {languageOptions.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => changeLanguage(lang.code)}
                    className={`w-full px-4 py-3 text-left flex items-center space-x-3 hover:bg-white/5 transition-colors duration-300 first:rounded-t-xl last:rounded-b-xl ${
                      currentLanguage === lang.code ? 'text-bnb-yellow bg-white/5' : 'text-gray-200'
                    }`}
                  >
                    <span className="text-lg">{lang.flag}</span>
                    <span className="font-medium">{lang.name}</span>
                  </button>
                ))}
              </div>
            </div>

         {/* Mobile Menu Button */}
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="p-2 md:hidden rounded-lg hover:bg-white/5 transition-colors duration-300"
            aria-label="Toggle menu"
          >
            {showMobileMenu ? (
              <X className="w-6 h-6 text-gray-200" />
            ) : (
              <Menu className="w-6 h-6 text-gray-200" />
            )}
          </button>
        </div>
  
        
        <div 
          className={`fixed inset-0 bg-gradient-to-b from-gray-900 to-gray-800/95 backdrop-blur-lg md:hidden transition-transform duration-500 ${
            showMobileMenu ? 'translate-x-0' : 'translate-x-full'
          }`}
          style={{ top: 0, height: '100vh', perspective: '1000px' }} // 3D efekti için perspective ekledik
        >
          {/* Logo and Close Button */}
          <div className="flex items-center justify-between p-6">
            {/* Logo */}
            <div className="flex-shrink-0">
              <img 
                src="https://i.ibb.co/M9xJfW2/logoooobn.png" 
                alt="BNB Staking Logo" 
                className="h-10 md:h-12 transition-transform duration-300 transform hover:scale-105"
                style={{ transform: 'rotateY(0deg)', transformStyle: 'preserve-3d' }} // 3D etkisi ekleme
              />
            </div>
            {/* Close Button */}
            <button
              onClick={() => setShowMobileMenu(false)}
              className="p-2 rounded-full text-gray-200 hover:bg-gray-700 transition-colors duration-300 shadow-lg"
              aria-label="Close menu"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Navigation Links */}
          <nav className="flex flex-col items-center mt-8 space-y-4">
            {navItems.map(({ id, label }, index) => (
              <a
                key={id}
                href={`#${id}`}
                onClick={() => setShowMobileMenu(false)}
                className="text-xl font-semibold text-gray-200 hover:text-bnb-yellow py-2 transition-transform duration-300 transform hover:scale-105 shadow-lg"
                style={{
                  animationDelay: `${index * 100}ms`,
                  animation: showMobileMenu ? 'slideIn 0.5s ease forwards' : 'none',
                  transform: 'translateZ(50px)' // 3D efekt için translateZ kullanımı
                }}
              >
                {t(`nav.${id}`, label)}
              </a>
            ))}
          </nav>

          <style jsx>{`
            @keyframes slideIn {
              from {
                opacity: 0;
                transform: translateX(20px);
              }
              to {
                opacity: 1;
                transform: translateX(0);
              }
            }
          `}</style>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateX(20px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
      `}</style>
    </header>
  );
};

export default Header;