import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HeroSection from './sections/HeroSection';
import WhyChooseUs from './sections/WhyChooseUs';
import StakingSection from './sections/StakingSection';
import ActiveStakes from './sections/ActiveStakes';
import Statistics from './sections/Statistics';
import FAQ from './sections/FAQ';
import Referral from './sections/Referral';
import ReferralPage from '../pages/ReferralPage';
import { useReferral } from '../hooks/useReferral';
import { useAlert } from '../providers/AlertProvider';
import ReferralDashboard from '../pages/ReferralDashboard';

const HomePage = ({ address }) => {
  if (!address) {
    return (
      <div className="flex flex-col min-h-screen">
        <section id="home"><HeroSection /></section>
        <section id="why-choose-us"><WhyChooseUs /></section>
        <section id="istatistikler"><Statistics /></section>
         <section id="referral"><ReferralDashboard /></section>
        <section id="sss"><FAQ /></section>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <section id="stake"><StakingSection /></section>
      <section id="aktif-stakeler"><ActiveStakes /></section>
      <section id="referral"><ReferralDashboard /></section>
      <section id="istatistikler"><Statistics /></section>
       <section id="sss"><FAQ /></section>
    </div>
  );
};

const MainContent = () => {
  const { address } = useAccount();
  const { getReferralFromUrl, validateReferral } = useReferral();
  const { showAlert } = useAlert();
  const [isProcessingReferral, setIsProcessingReferral] = useState(false);

  useEffect(() => {
    const processReferral = async () => {
      if (!address || isProcessingReferral) {
        return;
      }

      const referrer = getReferralFromUrl();
      if (!referrer) {
        return;
      }

      try {
        setIsProcessingReferral(true);
        const validation = validateReferral(referrer);
        if (!validation.isValid) {
          showAlert('error', validation.error);
          return;
        }

        showAlert('info', 'Referral will be processed during your first stake');
        localStorage.setItem('pendingReferral', referrer);

      } catch (error) {
        console.error('Referral processing error:', error);
        showAlert('error', error.message || 'Failed to process referral');
      } finally {
        setIsProcessingReferral(false);
      }
    };

    processReferral();
  }, [address, isProcessingReferral, getReferralFromUrl, validateReferral, showAlert]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage address={address} />} />
        <Route path="/referrals" element={<ReferralPage />} />
        {/* Geçersiz URL'leri ana sayfaya yönlendir */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default MainContent;
