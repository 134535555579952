import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Coins, 
  Clock, 
  Trophy, 
  TrendingUp, 
  Shield, 
  Lock,
  ChevronDown
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

const FAQItem = ({ questionKey, answerKey, isOpen, onClick, icon: Icon, index }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className={`
        relative backdrop-blur-lg
        rounded-2xl overflow-hidden
        ${isOpen ? 'ring-2 ring-[#F3BA2F]' : 'hover:ring-2 hover:ring-[#F3BA2F]/30'}
        transition-all duration-300
      `}
    >
      <button
        onClick={onClick}
        className={`
          w-full p-8
          bg-white/80 dark:bg-gray-800/80
          flex items-center gap-6
          group cursor-pointer
          transition-all duration-300
        `}
      >
        <motion.div
          animate={{ 
            rotate: isOpen ? 360 : 0,
            scale: isOpen ? 1.1 : 1
          }}
          className={`
            p-4 rounded-xl
            ${isOpen 
              ? 'bg-gradient-to-br from-[#F3BA2F] to-[#FFD700]' 
              : 'bg-gray-100 dark:bg-gray-700'
            }
            transition-colors duration-300
          `}
        >
          <Icon className={`
            w-6 h-6
            ${isOpen ? 'text-white' : 'text-gray-500 dark:text-gray-400'}
          `} />
        </motion.div>

        <div className="flex-grow text-left">
          <motion.h3
            animate={{ color: isOpen ? '#F3BA2F' : '' }}
            className={`
              text-xl font-semibold
              ${isOpen 
                ? 'text-[#F3BA2F] dark:text-[#F3BA2F]' 
                : 'text-gray-900 dark:text-gray-100'
              }
            `}
          >
            {t(questionKey)}
          </motion.h3>
        </div>

        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          className="flex-shrink-0"
        >
          <ChevronDown className={`
            w-6 h-6
            ${isOpen ? 'text-[#F3BA2F]' : 'text-gray-400'}
          `} />
        </motion.div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white/60 dark:bg-gray-800/60"
          >
            <div className="relative p-8 pt-4 pl-24">
              {/* Dekoratif bağlantı çizgisi */}
              <div className="absolute left-[3.25rem] top-0 w-px h-full bg-gradient-to-b from-[#F3BA2F] to-transparent" />

              <motion.p
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                className="text-gray-600 dark:text-gray-300 leading-relaxed"
              >
                {t(answerKey)}
              </motion.p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQ = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    { questionKey: 'faq.questions.what.question', answerKey: 'faq.questions.what.answer', icon: Coins },
    { questionKey: 'faq.questions.minimum.question', answerKey: 'faq.questions.minimum.answer', icon: Clock },
    { questionKey: 'faq.questions.rewards.question', answerKey: 'faq.questions.rewards.answer', icon: Trophy },
    { questionKey: 'faq.questions.apr.question', answerKey: 'faq.questions.apr.answer', icon: TrendingUp },
    { questionKey: 'faq.questions.security.question', answerKey: 'faq.questions.security.answer', icon: Shield },
    { questionKey: 'faq.questions.unstake.question', answerKey: 'faq.questions.unstake.answer', icon: Lock }
  ];

  return (
    <section id='faq' className="relative py-24 overflow-hidden">
      {/* Dekoratif arka plan elementleri */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute top-0 -left-1/4 w-96 h-96 bg-[#F3BA2F] rounded-full opacity-[0.15] blur-3xl" />
        <div className="absolute bottom-0 -right-1/4 w-96 h-96 bg-[#F3BA2F] rounded-full opacity-[0.15] blur-3xl" />
      </div>

      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-20"
        >
          <h2 className="text-5xl font-bold mb-4">
            <span className="bg-gradient-to-r from-[#F3BA2F] via-[#FFD700] to-[#F3BA2F] bg-clip-text text-transparent 
            animate-gradient bg-300% dark:from-[#F3BA2F] dark:via-[#FFD700] dark:to-[#F3BA2F]">
              {t('faq.title')}
            </span>
          </h2>
        </motion.div>

        <div className="max-w-4xl mx-auto space-y-6">
          {faqItems.map((faq, index) => (
            <FAQItem
              key={index}
              {...faq}
              index={index}
              isOpen={openIndex === index}
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;