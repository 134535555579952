import React from 'react';
import { LanguageProvider } from './LanguageProvider';
import ThemeProvider from './ThemeProvider';
import AlertProvider from './AlertProvider';
import RainbowKitProvider from './RainbowKitProvider';
import ReferralProvider from './ReferralProvider';
import { WagmiConfig } from './WagmiConfig';
import Layout from '../components/layout/Layout';

const ThemeAwareContent = ({ children, isDarkMode }) => {
    return (
        <WagmiConfig>
            <AlertProvider>
                <ReferralProvider>
                    <RainbowKitProvider isDarkMode={isDarkMode}>
                        <Layout>
                            {children}
                        </Layout>
                    </RainbowKitProvider>
                </ReferralProvider>
            </AlertProvider>
        </WagmiConfig>
    );
};

const AppProvider = ({ children }) => {
    return (
        <LanguageProvider>
            <ThemeProvider>
                {({ isDarkMode }) => (
                    <ThemeAwareContent isDarkMode={isDarkMode}>
                        {children}
                    </ThemeAwareContent>
                )}
            </ThemeProvider>
        </LanguageProvider>
    );
};

export default AppProvider;