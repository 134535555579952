import { WagmiProvider, createConfig, http } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { bsc, bscTestnet } from 'wagmi/chains';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';

// WalletConnect projectId
const projectId = '36ce6542520b28ad3081f665f01fe1cc'; // WalletConnect'ten almanız gerekiyor

// Query Client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 3,
            staleTime: 1000 * 60 * 5, // 5 minutes
        },
    },
});

// Wagmi config
const config = getDefaultConfig({
    appName: 'Multi-Token Staking Platform',
    projectId,
    chains: [bsc, bscTestnet],
    transports: {
        [bsc.id]: http(),
        [bscTestnet.id]: http(),
    },
    ssr: true // Eğer Next.js kullanıyorsanız
});

export function WagmiConfig({ children }) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    );
}