// TokenSelector/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { TOKEN_CATEGORIES, SUPPORTED_TOKENS } from '../../web3/config';
import TokenCard from './TokenCard';
import CategorySelector from './CategorySelector';
import SearchBar from './SearchBar';
import { generateMockPriceHistory } from './utils';

// Chart.js kayıt
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler
);

const TokenSelector = ({ selectedToken, onSelectToken, amount, duration }) => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [hoveredToken, setHoveredToken] = useState(null);
  const containerRef = useRef(null);

  // Token filtreleme
  const filteredTokens = React.useMemo(() => {
    return Object.entries(SUPPORTED_TOKENS).filter(([symbol, token]) => {
      const matchesSearch = token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          symbol.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || token.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [searchQuery, selectedCategory]);

  // Pagination
  const tokensPerPage = window.innerWidth < 640 ? 4 : window.innerWidth < 1024 ? 6 : 9;
  const totalPages = Math.ceil(filteredTokens.length / tokensPerPage);
  const paginatedTokens = filteredTokens.slice(page * tokensPerPage, (page + 1) * tokensPerPage);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full max-w-7xl mx-auto"
    >
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-50 via-blue-50 to-cyan-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 -z-10">
        <div className="absolute inset-0 bg-grid-pattern opacity-[0.2] dark:opacity-[0.1]" />
      </div>

      {/* Header Section */}
      <div className="mb-8 space-y-6">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
          <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-bnb-yellow to-yellow-500">
            Select Token
          </h2>
          <SearchBar 
            value={searchQuery} 
            onChange={setSearchQuery} 
          />
        </div>
        <CategorySelector
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
      </div>

      {/* Tokens Grid */}
      <div className="relative min-h-[400px]">
        <AnimatePresence mode="wait">
          <motion.div
            key={page + selectedCategory + searchQuery}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            {paginatedTokens.map(([symbol, token], index) => (
              <TokenCard
                key={symbol}
                symbol={symbol}
                token={token}
                isSelected={selectedToken === symbol}
                onSelect={() => onSelectToken(symbol)}
                duration={duration}
                index={index}
                setHoveredToken={setHoveredToken}
              />
            ))}
          </motion.div>
        </AnimatePresence>

        {/* Empty State */}
        {filteredTokens.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <div className="text-center space-y-4">
              <motion.div
                animate={{ rotate: [0, 10, -10, 0] }}
                transition={{ repeat: Infinity, duration: 2 }}
              >
                <span className="text-6xl">🔍</span>
              </motion.div>
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                No tokens found
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Try adjusting your search or filter criteria
              </p>
            </div>
          </motion.div>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-8 flex justify-center gap-2">
          {Array.from({ length: totalPages }).map((_, i) => (
            <button
              key={i}
              onClick={() => setPage(i)}
              className={`w-3 h-3 rounded-full transition-all duration-300 ${
                page === i
                  ? 'bg-bnb-yellow scale-110'
                  : 'bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600'
              }`}
            />
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default TokenSelector;