import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Dil dosyalarını import ediyoruz
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';
import translationDE from './locales/de.json';
import translationFR from './locales/fr.json';
import translationES from './locales/es.json';
import translationRU from './locales/ru.json';
import translationZH from './locales/zh.json';
import translationJA from './locales/ja.json';
import translationPT from './locales/pt-BR.json';
import translationAZ from './locales/az.json';
import translationAR from './locales/ar.json';

// Kullanılabilir diller
const getFlagUrl = (countryCode) => `https://www.countryflags.io/${countryCode}/flat/64.png`;
// Kullanılabilir diller
export const languages = [
  { 
    code: 'tr', 
    name: 'Türkçe', 
    flag: getFlagUrl('tr')
  },
  { 
    code: 'en', 
    name: 'English', 
    flag: getFlagUrl('gb')
  },
  { 
    code: 'de', 
    name: 'Deutsch', 
    flag: getFlagUrl('de')
  },
  { 
    code: 'fr', 
    name: 'Français', 
    flag: getFlagUrl('fr')
  },
  { 
    code: 'es', 
    name: 'Español', 
    flag: getFlagUrl('es')
  },
  { 
    code: 'ru', 
    name: 'Русский', 
    flag: getFlagUrl('ru')
  },
  { 
    code: 'zh', 
    name: '中文', 
    flag: getFlagUrl('cn')
  },
  { 
    code: 'ja', 
    name: '日本語', 
    flag: getFlagUrl('jp')
  },
  { 
    code: 'pt-BR', 
    name: 'Português', 
    flag: getFlagUrl('br')
  },
  { 
    code: 'az', 
    name: 'Azərbaycan', 
    flag: getFlagUrl('az')
  },
  { 
    code: 'ar', 
    name: 'العربية', 
    flag: getFlagUrl('sa')
  }
];
// Dil kaynaklarını hazırla
const resources = {
  tr: {
    translation: {
      ...translationTR,
      staking: {
        ...translationTR.staking,
        periods: {
          day1: '1 Gün',
          day7: '7 Gün',
          day30: '30 Gün',
          day365: '365 Gün'
        },
        periodDescriptions: {
          day1: '24 saat kilitli kalır',
          day7: '7 gün kilitli kalır',
          day30: '30 gün kilitli kalır',
          day365: '365 gün kilitli kalır'
        }
      }
    }
  },
  en: {
    translation: {
      ...translationEN,
      staking: {
        ...translationEN.staking,
        periods: {
          day1: '1 Day',
          day7: '7 Days',
          day30: '30 Days',
          day365: '365 Days'
        },
        periodDescriptions: {
          day1: 'Locked for 24 hours',
          day7: 'Locked for 7 days',
          day30: 'Locked for 30 days',
          day365: 'Locked for 365 days'
        }
      }
    }
  },
  de: {
    translation: translationDE
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  },
  ru: {
    translation: translationRU
  },
  zh: {
    translation: translationZH
  },
  ja: {
    translation: translationJA
  },
  'pt-BR': {
    translation: translationPT
  },
  az: {
    translation: translationAZ
  },
  ar: {
    translation: translationAR
  }
};

// i18n konfigürasyonu
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    defaultNS: 'translation',
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}'
    },

    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng'
    },

    // Nesne dönüşü ve hata yönetimi
    returnObjects: true,
    returnNull: false,
    parseMissingKeyHandler: (key) => {
      console.warn(`Missing translation key: ${key}`);
      return key;
    },

    // Çeviri olmayan durumlar için
    keySeparator: '.',
    nsSeparator: ':',
    pluralSeparator: '_',
    contextSeparator: '_'
  });

// Tarayıcı dilini kontrol et ve varsayılan dili ayarla
const browserLang = navigator.language.split('-')[0];
const defaultLang = languages.some(lang => lang.code === browserLang) ? browserLang : 'en';

// Dil seçimi yoksa varsayılan dili ayarla
if (!localStorage.getItem('i18nextLng')) {
  i18n.changeLanguage(defaultLang);
}

// Arapça için RTL desteği
i18n.on('languageChanged', (lng) => {
  if (lng === 'ar') {
    document.documentElement.dir = 'rtl';
  } else {
    document.documentElement.dir = 'ltr';
  }
});

// Hata durumunda console.log
i18n.on('missingKey', (lngs, namespace, key) => {
  console.warn(`Missing translation - Langs: ${lngs}, NS: ${namespace}, Key: ${key}`);
});

export default i18n;