import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';


const Layout = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // LocalStorage'dan dark mode tercihini al
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  // Theme değişikliğini yönet
  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  // Theme değiştiğinde localStorage'ı güncelle
  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');

    // HTML elementine dark class'ını ekle/çıkar
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'dark' : ''}`}>
      <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
      <main className="flex-grow bg-white dark:bg-gray-900 transition-colors duration-300">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;