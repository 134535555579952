import React from 'react';
import "@rainbow-me/rainbowkit/styles.css";
import {
    RainbowKitProvider as RainbowKit,
    getDefaultWallets,
    darkTheme,
    lightTheme,
} from "@rainbow-me/rainbowkit";
import { useTranslation } from 'react-i18next';
import { bsc } from 'wagmi/chains';

const projectId = '36ce6542520b28ad3081f665f01fe1cc'; // WagmiConfig ile aynı projectId

const { wallets } = getDefaultWallets({
    appName: 'Multi-Token Staking Platform',
    projectId,
    chains: [bsc]
});

const RainbowKitProvider = ({ children, isDarkMode }) => {
    const { t } = useTranslation();

    const messages = {
        connectWallet: t('wallet.connect'),
        disconnect: t('wallet.disconnect'),
        copied: t('wallet.copied'),
        copyAddress: t('wallet.copyAddress'),
        switchNetwork: t('wallet.switchNetwork'),
        wrongNetwork: t('wallet.wrongNetwork'),
        connectedTo: t('wallet.connectedTo'),
        connectionFailed: t('wallet.connectionFailed'),
        viewExplorer: t('wallet.viewExplorer'),
        transactionPending: t('wallet.transactionPending'),
        transactionSuccess: t('wallet.transactionSuccess'),
        transactionFailed: t('wallet.transactionFailed'),
    };

    const themeOptions = {
        accentColor: "rgba(243, 186, 47, 1)",
        accentColorForeground: "black",
        borderRadius: "medium",
        fontStack: "system",
        overlayBlur: "small",
    };

    return (
        <RainbowKit
            chains={[bsc]}
            wallets={wallets}
            initialChain={bsc}
            modalSize="wide"
            theme={isDarkMode ? darkTheme(themeOptions) : lightTheme(themeOptions)}
            showRecentTransactions={true}
            appInfo={{
                appName: "Multi-Token Staking Platform",
            }}
            locale={{
                ...messages,
                walletSelectionHeader: t('wallet.selectWallet'),
                searchWallet: t('wallet.searchWallet'),
                getWallet: t('wallet.getWallet'),
                connectingWallet: t('wallet.connecting'),
                recentWallet: t('wallet.recentWallet'),
                moreWallets: t('wallet.moreWallets'),
            }}
        >
            {children}
        </RainbowKit>
    );
};

export default RainbowKitProvider;