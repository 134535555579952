export const STAKING_ABI = [
  {
      "inputs": [
          {"internalType": "address", "name": "token", "type": "address"},
          {"internalType": "uint256", "name": "amount", "type": "uint256"},
          {"internalType": "uint256", "name": "duration", "type": "uint256"},
          {"internalType": "address", "name": "referrer", "type": "address"}
      ],
      "name": "createStake",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [{"internalType": "uint256", "name": "stakeId", "type": "uint256"}],
      "name": "withdrawStake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
      {
        "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
        "name": "getUserStakes",
        "outputs": [{
          "components": [
            {"internalType": "uint256", "name": "amount", "type": "uint256"},
            {"internalType": "uint256", "name": "startTime", "type": "uint256"},
            {"internalType": "uint256", "name": "endTime", "type": "uint256"},
            {"internalType": "uint256", "name": "lastRewardCalculation", "type": "uint256"},
            {"internalType": "bool", "name": "isWithdrawn", "type": "bool"},
            {"internalType": "address", "name": "tokenAddress", "type": "address"}
          ],
          "internalType": "struct MultiTokenStaking.StakeInfo[]",
          "name": "",
          "type": "tuple[]"
        }],
        "stateMutability": "view",
        "type": "function"
      },
  {
      "inputs": [],
      "name": "referralPercent",
      "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
      "stateMutability": "view",
      "type": "function"
  },
  // Referral fonksiyonları eklendi
{
        "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
        "name": "getReferralInfo",
        "outputs": [
            {"internalType": "address", "name": "referrer", "type": "address"},
            {"internalType": "uint256", "name": "totalReferrals", "type": "uint256"},
            {"internalType": "uint256", "name": "totalRewards", "type": "uint256"},
            {"internalType": "address[]", "name": "referredUsers", "type": "address[]"},
            {"internalType": "uint256", "name": "totalReferralVolume", "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "referralPercent",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }
];
export const TOKEN_ABI = [
  // Token ABI mevcut haliyle kalabilir
  {
      "inputs": [
          {"name": "spender", "type": "address"},
          {"name": "amount", "type": "uint256"}
      ],
      "name": "approve",
      "outputs": [{"type": "bool"}],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {"name": "owner", "type": "address"},
          {"name": "spender", "type": "address"}
      ],
      "name": "allowance",
      "outputs": [{"type": "uint256"}],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [{"name": "account", "type": "address"}],
      "name": "balanceOf",
      "outputs": [{"type": "uint256"}],
      "stateMutability": "view",
      "type": "function"
  }
];

// Yardımcı fonksiyonlar aynı kalabilir
export const getAbiFunction = (abi, functionName) => {
  return abi.find(item => item.name === functionName);
};

export const formatStake = (stake) => {
  return {
      amount: stake.amount.toString(),
      startTime: Number(stake.startTime),
      endTime: Number(stake.endTime),
      apr: Number(stake.apr),
      rewards: stake.rewards.toString(),
      isWithdrawn: stake.isWithdrawn,
      tokenAddress: stake.tokenAddress
  };
};