import React from 'react';
import { motion } from 'framer-motion';
import { Search } from 'lucide-react';

const SearchBar = ({ value, onChange }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative w-full md:w-auto"
    >
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search tokens..."
        className="w-full md:w-[300px] pl-12 pr-4 py-3 bg-white
          dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 
              focus:outline-none focus:ring-2 focus:ring-bnb-yellow transition-all duration-300
              placeholder-gray-400 dark:placeholder-gray-500"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
          </motion.div>
        );
      };

      export default SearchBar;