import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

const ThemeProvider = ({ children }) => {
    // Initial state'i localStorage'dan veya sistem tercihinden al
    const [isDarkMode, setIsDarkMode] = useState(() => {
        if (typeof window !== 'undefined') {
            const savedTheme = localStorage.getItem('theme');
            // Burayı true yaparak varsayılan olarak dark mode başlatıyoruz
            return savedTheme ? savedTheme === 'dark' : true;
        }
        return true; // Varsayılan olarak dark mode
    });

    // Sistem dark mode tercihini dinle
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            const savedTheme = localStorage.getItem('theme');
            if (!savedTheme) {
                setIsDarkMode(e.matches);
            }
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    // Dark mode değişikliklerini uygula
    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        }
    }, [isDarkMode]);

    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    const value = {
        isDarkMode,
        toggleTheme,
        setIsDarkMode
    };

    return (
        <ThemeContext.Provider value={value}>
            {typeof children === 'function' ? children({ isDarkMode }) : children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;