import React, { createContext, useContext, useState } from 'react';
import Alert from '../components/ui/Alert';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    isOpen: false,
    type: 'info', // success, error, warning, info
    title: '',
    message: '',
  });

  const showAlert = (type, title, message) => {
    setAlert({
      isOpen: true,
      type,
      title,
      message,
    });
    // 5 saniye sonra alert'i otomatik kapat
    setTimeout(() => {
      hideAlert();
    }, 5000);
  };

  const hideAlert = () => {
    setAlert(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  // Stake işlemleri için alert fonksiyonları
  const showStakeSuccess = (amount, token, duration) => {
    showAlert(
      'success',
      'Stake İşlemi Başarılı',
      `${amount} ${token} başarıyla ${duration} günlük stake edildi.`
    );
  };

  const showStakeError = (error) => {
    showAlert(
      'error',
      'Stake Hatası',
      error || 'Stake işlemi sırasında bir hata oluştu.'
    );
  };

  const showWithdrawSuccess = (amount, token, rewards) => {
    showAlert(
      'success',
      'Çekim Başarılı',
      `${amount} ${token} ve ${rewards} ${token} ödül başarıyla çekildi.`
    );
  };

  // Referans ile ilgili özel alert fonksiyonları
  const showReferralSuccess = (referrer, amount, token) => {
    showAlert(
      'success',
      'Referans Başarılı',
      `${amount} ${token} referans ödülü ${referrer.slice(0, 6)}...${referrer.slice(-4)} adresine gönderildi.`
    );
  };

  const showReferralRegistered = (referrer) => {
    showAlert(
      'success',
      'Referans Kaydedildi',
      `${referrer.slice(0, 6)}...${referrer.slice(-4)} referans kodunuz başarıyla kaydedildi.`
    );
  };

  const showReferralError = (error) => {
    showAlert(
      'error',
      'Referans Hatası',
      error || 'Referans işlemi sırasında bir hata oluştu.'
    );
  };

  // Token işlemleri için alert fonksiyonları
  const showTokenApprovalSuccess = (token) => {
    showAlert(
      'success',
      'Token Onayı Başarılı',
      `${token} için harcama izni başarıyla verildi.`
    );
  };

  const showTokenApprovalPending = (token) => {
    showAlert(
      'info',
      'Token Onayı Bekleniyor',
      `${token} için harcama izni işlemi onayınızı bekliyor.`
    );
  };

  const showTransactionPending = () => {
    showAlert(
      'info',
      'İşlem Beklemede',
      'İşleminiz blockchain ağına gönderildi, lütfen bekleyin.'
    );
  };

  const showNetworkError = () => {
    showAlert(
      'error',
      'Ağ Hatası',
      'Lütfen cüzdanınızın BSC ağına bağlı olduğundan emin olun.'
    );
  };

  return (
    <AlertContext.Provider 
      value={{ 
        showAlert,
        hideAlert,
        showStakeSuccess,
        showStakeError,
        showWithdrawSuccess,
        showReferralSuccess,
        showReferralRegistered,
        showReferralError,
        showTokenApprovalSuccess,
        showTokenApprovalPending,
        showTransactionPending,
        showNetworkError
      }}
    >
      {children}
      <Alert
        isOpen={alert.isOpen}
        type={alert.type}
        title={alert.title}
        message={alert.message}
        onClose={hideAlert}
      />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export default AlertProvider;