import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
 AreaChart, Area, XAxis, YAxis, CartesianGrid,
 Tooltip, ResponsiveContainer, Legend, Line, LineChart
} from 'recharts';
import CountUp from 'react-countup';
import {
 TrendingUp, TrendingDown, Users, Activity,
 RefreshCcw, Clock, DollarSign, BarChart2,
 ChevronUp, ChevronDown, Globe
} from 'lucide-react';



const formatValue = (value, type = 'number', options = {}) => {
 const { decimals = 2, prefix = '', suffix = '' } = options;

 if (value === null || value === undefined) return '-';

 if (type === 'currency') {
   return `${prefix}${Number(value).toFixed(decimals)}${suffix}`;
 }

 if (type === 'percentage') {
   return `${Number(value).toFixed(decimals)}%`;
 }

 if (type === 'compact') {
   if (value >= 1000000) {
     return `${prefix}${(value / 1000000).toFixed(decimals)}M${suffix}`;
   }
   if (value >= 1000) {
     return `${prefix}${(value / 1000).toFixed(decimals)}K${suffix}`;
   }
 }

 return `${prefix}${Number(value).toFixed(decimals)}${suffix}`;
};

// Custom Card Component
const MetricCard = ({ title, value, change, icon: Icon, trend, chartData, loading }) => {
 const [isHovered, setIsHovered] = useState(false);
 const trendIsUp = parseFloat(change) >= 0;

 return (
   <motion.div
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     transition={{ duration: 0.3 }}
     onMouseEnter={() => setIsHovered(true)}
     onMouseLeave={() => setIsHovered(false)}
     className={`
       relative overflow-hidden rounded-2xl
       bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900
       border border-gray-100 dark:border-gray-700
       transform transition-all duration-300
       ${isHovered ? 'scale-[1.02] shadow-xl' : 'shadow-lg'}
     `}
   >
     {/* Animated Background */}
     <div className="absolute inset-0 bg-grid-pattern opacity-5" />

     {/* Glass Effect Overlay */}
     <div className="absolute inset-0 bg-white/50 dark:bg-gray-800/50 backdrop-blur-[2px]" />

     {/* Content */}
     <div className="relative p-6 z-10">
       <div className="flex items-center justify-between mb-4">
         <div className={`
           p-3 rounded-xl transition-all duration-300
           ${isHovered 
             ? 'bg-bnb-yellow text-white' 
             : 'bg-gray-100 dark:bg-gray-700/50 text-gray-600 dark:text-gray-300'
           }
         `}>
           <Icon className="w-6 h-6" />
         </div>

         <div className={`
           flex items-center gap-1 px-3 py-1.5 rounded-full text-sm font-medium
           transition-all duration-300
           ${trendIsUp
             ? 'text-green-700 bg-green-100 dark:text-green-400 dark:bg-green-900/30'
             : 'text-red-700 bg-red-100 dark:text-red-400 dark:bg-red-900/30'
           }
         `}>
           {trendIsUp ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
           {Math.abs(change)}%
         </div>
       </div>

       <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
         {title}
       </h3>

       <div className="mt-2">
         <CountUp
           end={parseFloat(value)}
           decimals={2}
           duration={1}
           separator=","
           prefix={value.startsWith('₿') ? '₿ ' : ''}
           className="text-2xl font-bold text-gray-900 dark:text-white"
         />
       </div>

       {/* Mini Chart */}
       {chartData && chartData.length > 0 && (
         <div className="h-16 mt-4">
           <ResponsiveContainer width="100%" height="100%">
             <AreaChart data={chartData}>
               <defs>
                 <linearGradient id={`gradient-${title}`} x1="0" y1="0" x2="0" y2="1">
                   <stop 
                     offset="0%" 
                     stopColor={trendIsUp ? '#10B981' : '#EF4444'} 
                     stopOpacity={0.2}
                   />
                   <stop 
                     offset="100%" 
                     stopColor={trendIsUp ? '#10B981' : '#EF4444'} 
                     stopOpacity={0}
                   />
                 </linearGradient>
               </defs>
               <Area
                 type="monotone"
                 dataKey="value"
                 stroke={trendIsUp ? '#10B981' : '#EF4444'}
                 strokeWidth={2}
                 fill={`url(#gradient-${title})`}
               />
             </AreaChart>
           </ResponsiveContainer>
         </div>
       )}

       {/* Hover Border Effect */}
       <div className={`
         absolute inset-0 border-2 border-bnb-yellow rounded-2xl
         transition-opacity duration-300
         ${isHovered ? 'opacity-100' : 'opacity-0'}
       `} />
     </div>
   </motion.div>
 );
};
// Advanced Chart Component
const AdvancedChart = ({ data, type = 'area', loading, height = 400, title, dataKey = 'value' }) => {
 const [hoveredData, setHoveredData] = useState(null);

 const CustomTooltip = ({ active, payload, label }) => {
   if (!active || !payload) return null;

   return (
     <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-4 border border-gray-200 dark:border-gray-700">
       <p className="font-medium text-gray-900 dark:text-white mb-2">{label}</p>
       {payload.map((entry, index) => (
         <div key={index} className="flex items-center gap-2">
           <div 
             className="w-3 h-3 rounded-full"
             style={{ backgroundColor: entry.color }}
           />
           <span className="text-sm text-gray-600 dark:text-gray-400">
             {entry.name}:
           </span>
           <span className="text-sm font-semibold text-gray-900 dark:text-white">
             {entry.value.toLocaleString()}
           </span>
         </div>
       ))}
     </div>
   );
 };

 return (
   <motion.div
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     transition={{ duration: 0.3 }}
     className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-100 dark:border-gray-700 p-6"
   >
     <div className="flex items-center justify-between mb-6">
       <h3 className="text-lg font-bold text-gray-900 dark:text-white">
         {title}
       </h3>
       <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
         <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
         Live Data
       </div>
     </div>

     <div className="h-[400px] relative">
       {loading ? (
         <div className="absolute inset-0 flex items-center justify-center">
           <div className="w-12 h-12 border-4 border-bnb-yellow border-t-transparent rounded-full animate-spin" />
         </div>
       ) : (
         <ResponsiveContainer width="100%" height="100%">
           <AreaChart data={data} onMouseMove={(data) => setHoveredData(data)}>
             <defs>
               <linearGradient id={`colorGradient-${title}`} x1="0" y1="0" x2="0" y2="1">
                 <stop offset="5%" stopColor="#F3BA2F" stopOpacity={0.3}/>
                 <stop offset="95%" stopColor="#F3BA2F" stopOpacity={0}/>
               </linearGradient>
             </defs>
             <CartesianGrid 
               strokeDasharray="3 3" 
               stroke="rgba(156, 163, 175, 0.2)" 
               vertical={false}
             />
             <XAxis 
               dataKey="date" 
               tick={{ fill: 'currentColor' }}
               axisLine={false}
               tickLine={false}
               dy={10}
             />
             <YAxis
               tick={{ fill: 'currentColor' }}
               axisLine={false}
               tickLine={false}
               dx={-10}
               tickFormatter={(value) => formatValue(value, 'compact')}
             />
             <Tooltip content={<CustomTooltip />} />
             <Area
               type="monotone"
               dataKey={dataKey}
               stroke="#F3BA2F"
               strokeWidth={2}
               fill={`url(#colorGradient-${title})`}
               animationDuration={1000}
             />
           </AreaChart>
         </ResponsiveContainer>
       )}
     </div>
   </motion.div>
 );
};
// Main Statistics Component
const Statistics = () => {
 const [selectedPeriod, setSelectedPeriod] = useState('7d');
 const [isRefreshing, setIsRefreshing] = useState(false);
 const [loading, setLoading] = useState(true);
 const [data, setData] = useState([]);

 // Mock data generator
 const generateData = (period) => {
   const periods = {
     '24h': 24,
     '7d': 168,
     '30d': 720,
     'ALL': 2160
   };

   const points = periods[period] || 24;
   const data = [];
   let currentValue = 1000 + Math.random() * 9000;

   for (let i = points; i >= 0; i--) {
     const date = new Date();
     date.setHours(date.getHours() - i);

     const value = currentValue * (1 + (Math.random() - 0.5) * 0.05);
     currentValue = value;

     data.push({
       date: date.toLocaleString('en-US', { 
         month: 'short',
         day: 'numeric',
         hour: '2-digit',
         minute: '2-digit'
       }),
       value: value,
       volume: value * 0.3 * (1 + Math.random() * 0.5),
       users: Math.floor(value / 100),
       transactions: Math.floor(value / 50)
     });
   }
   return data;
 };

 // Data fetching
 useEffect(() => {
   const fetchData = async () => {
     setLoading(true);
     // Simulate API call
     await new Promise(resolve => setTimeout(resolve, 1000));
     const newData = generateData(selectedPeriod);
     setData(newData);
     setLoading(false);
   };

   fetchData();
 }, [selectedPeriod]);

 // Metrics calculation
 const metrics = useMemo(() => {
   if (!data || data.length < 2) return [];

   const current = data[data.length - 1];
   const previous = data[data.length - 2];
   const calculateChange = (curr, prev) => 
     ((curr - prev) / prev * 100).toFixed(2);

   return [
     {
       title: 'Total Value Locked',
       value: formatValue(current.value, 'currency', { prefix: '₿ ' }),
       change: calculateChange(current.value, previous.value),
       icon: DollarSign,
       chartData: data.slice(-24)
     },
     {
       title: '24h Volume',
       value: formatValue(current.volume, 'currency', { prefix: '₿ ' }),
       change: calculateChange(current.volume, previous.volume),
       icon: BarChart2,
       chartData: data.slice(-24)
     },
     {
       title: 'Active Users',
       value: formatValue(current.users, 'compact'),
       change: calculateChange(current.users, previous.users),
       icon: Users,
       chartData: data.slice(-24)
     },
     {
       title: 'Transactions',
       value: formatValue(current.transactions, 'compact'),
       change: calculateChange(current.transactions, previous.transactions),
       icon: Activity,
       chartData: data.slice(-24)
     }
   ];
 }, [data]);

 // Refresh handler
 const handleRefresh = async () => {
   setIsRefreshing(true);
   setLoading(true);
   await new Promise(resolve => setTimeout(resolve, 1000));
   setData(generateData(selectedPeriod));
   setLoading(false);
   setIsRefreshing(false);
 };

 return (
   <section className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
     <div className="max-w-7xl mx-auto">
       {/* Header Section */}
       <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-6 mb-12">
         <motion.div
           initial={{ opacity: 0, y: 20 }}
           animate={{ opacity: 1, y: 0 }}
           className="flex-1 min-w-0"
         >
           <h1 className="text-4xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-bnb-yellow to-yellow-500">
             Statistics
           </h1>
           <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
             Real-time analytics and market insights
           </p>
         </motion.div>

         <div className="flex flex-wrap gap-4">
           {/* Period Selector */}
           <div className="bg-white dark:bg-gray-800 rounded-xl p-1.5 shadow-lg border border-gray-100 dark:border-gray-700">
             <div className="flex space-x-1">
               {['24h', '7d', '30d', 'ALL'].map((period) => (
                 <motion.button
                   key={period}
                   whileHover={{ scale: 1.05 }}
                   whileTap={{ scale: 0.95 }}
                   onClick={() => setSelectedPeriod(period)}
                   className={`
                     px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                     ${selectedPeriod === period
                       ? 'bg-bnb-yellow text-black shadow-lg'
                       : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
                     }
                   `}
                 >
                   {period}
                 </motion.button>
               ))}
             </div>
           </div>

           {/* Refresh Button */}
           <motion.button
             whileHover={{ scale: 1.05 }}
             whileTap={{ scale: 0.95 }}
             onClick={handleRefresh}
             disabled={isRefreshing || loading}
             className={`
               p-3 rounded-xl bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-400
               border border-gray-100 dark:border-gray-700 shadow-lg
               hover:bg-gray-100 dark:hover:bg-gray-700
               disabled:opacity-50 disabled:cursor-not-allowed
               transition-all duration-200
             `}
           >
             <RefreshCcw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
           </motion.button>
         </div>
       </div>

       {/* Main Content */}
       <div className="space-y-8">
         {/* Metrics Grid */}
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
           {metrics.map((metric, index) => (
             <MetricCard
               key={metric.title}
               {...metric}
               loading={loading}
             />
           ))}
         </div>

         {/* Charts Grid */}
         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
           <AdvancedChart
             data={data}
             loading={loading}
             title="Value Locked Over Time"
             dataKey="value"
           />
           <AdvancedChart
             data={data}
             loading={loading}
             title="Trading Volume"
             dataKey="volume"
           />
         </div>

         {/* Network Stats */}
         <motion.div
           initial={{ opacity: 0, y: 20 }}
           animate={{ opacity: 1, y: 0 }}
           className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg border border-gray-100 dark:border-gray-700"
         >
           <div className="flex items-center justify-between mb-6">
             <h3 className="text-lg font-bold text-gray-900 dark:text-white flex items-center gap-2">
               <Globe className="w-5 h-5 text-bnb-yellow" />
               Network Status
             </h3>
             <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
               <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
               Live
             </div>
           </div>

           <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
             {[
               { label: 'Block Height', value: '34,526,789' },
               { label: 'Network APR', value: '12.45%' },
               { label: 'Active Validators', value: '128' },
               { label: 'Network Load', value: '67%' }
             ].map((stat, index) => (
               <div key={index} className="text-center">
                 <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                   {stat.label}
                 </div>
                 <div className="text-2xl font-bold text-gray-900 dark:text-white">
                   {stat.value}
                 </div>
               </div>
             ))}
           </div>
         </motion.div>
       </div>
     </div>
   </section>
 );
};

export default Statistics;