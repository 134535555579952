import React from 'react';
import { useAccount, useContractRead } from 'wagmi';
import { useAlert } from './AlertProvider';
import { STAKING_ADDRESS, SUPPORTED_TOKENS } from '../web3/config';
import { STAKING_ABI } from '../web3/contractABI';
import { ReferralContext } from '../providers/ReferralContext';
import { formatEther, parseEther } from 'viem';

export const ReferralProvider = ({ children }) => {
    const { address } = useAccount();
    const { showAlert } = useAlert();

    const { data: referralPercent } = useContractRead({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'referralPercent',
        watch: true
    });

    const generateReferralLink = () => {
        if (!address) return '';
        const baseUrl = window.location.origin;
        return `${baseUrl}?ref=${address}`;
    };

    const copyReferralLink = async () => {
        const link = generateReferralLink();
        if (!link) {
            showAlert('error', 'Please connect your wallet first');
            return;
        }

        try {
            await navigator.clipboard.writeText(link);
            showAlert('success', 'Referral link copied to clipboard!');
        } catch (error) {
            showAlert('error', 'Failed to copy referral link');
        }
    };

    const getReferralFromUrl = () => {
        if (typeof window === 'undefined') return null;
        const params = new URLSearchParams(window.location.search);
        const ref = params.get('ref');

        if (ref && ref.match(/^0x[a-fA-F0-9]{40}$/)) {
            return ref;
        }
        return null;
    };

    const validateReferral = (referrerAddress) => {
        if (!referrerAddress || !address) {
            return { isValid: false, error: 'Invalid addresses' };
        }
        if (referrerAddress === address) {
            return { isValid: false, error: 'Cannot refer yourself' };
        }
        if (referrerAddress === '0x0000000000000000000000000000000000000000') {
            return { isValid: false, error: 'Invalid referrer address' };
        }
        return { isValid: true, error: null };
    };

    const calculateReferralReward = (amount, tokenSymbol) => {
        if (!amount || !referralPercent) return 0;
        const token = SUPPORTED_TOKENS[tokenSymbol];
        if (!token) return 0;
        return (parseFloat(amount) * Number(referralPercent)) / 10000;
    };

    const value = {
        referralPercent: referralPercent ? Number(referralPercent) / 100 : 0,
        maxReferralPercent: 10,
        generateReferralLink,
        copyReferralLink,
        getReferralFromUrl,
        validateReferral,
        calculateReferralReward
    };

    return (
        <ReferralContext.Provider value={value}>
            {children}
        </ReferralContext.Provider>
    );
};

export default ReferralProvider;