import React from 'react';
import AppProvider from './providers/AppProvider';
import MainContent from './components/MainContent';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
    return (
        <ErrorBoundary>
            <AppProvider>
                <MainContent />
            </AppProvider>
        </ErrorBoundary>
    );
};

export default App;