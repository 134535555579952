import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Clock, TrendingUp, Coins, Timer, ArrowUpRight } from "lucide-react";
import { SUPPORTED_TOKENS, STAKING_PERIODS, getPeriodLabel, getTokenByAddress } from "../../web3/config";

const StakeCard = ({ stake, onUnstake, isLoading }) => {
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);
    const [timeLeft, setTimeLeft] = useState("");
    const [earnings, setEarnings] = useState(0);
    const [isUnstakeable, setIsUnstakeable] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    console.log('Initial Stake Data:', {
        stake,
        amount: stake.amount,
    });

    // Stake bilgilerini hesapla
    const stakeInfo = useMemo(() => {
        const token = getTokenByAddress(stake.tokenAddress);
        const duration = stake.endTime - stake.startTime;
        const period = duration === STAKING_PERIODS.MONTHLY ? STAKING_PERIODS.MONTHLY : STAKING_PERIODS.YEARLY;

        console.log('Stake Info Calculation:', {
            token,
            period,
            apr: token.apr[period],
            amount: stake.amount,
        });

        return {
            token,
            period,
            periodLabel: getPeriodLabel(period),
            apr: token.apr[period],
            amount: parseFloat(stake.amount).toFixed(4),
            rewards: stake.rewards
        };
    }, [stake]);

    // Anlık kazanç hesaplama fonksiyonu
    const calculateTotalReward = useCallback(() => {
        const stakeAmount = parseFloat(stake.amount);
        const apr = stakeInfo.apr;
        const durationInDays = (stake.endTime - stake.startTime) / (24 * 60 * 60); // Süreyi gün olarak hesapla

        // Toplam kazanç = Stake Miktarı * (APR / 100) * (Stake Süresi / 365)
        const totalReward = stakeAmount * (apr / 100) * (durationInDays / 365);

        console.log('Total Reward Calculation:', {
            stakeAmount,
            apr,
            durationInDays,
            totalReward
        });

        return totalReward;
    }, [stake, stakeInfo]);

    const formatDuration = (ms) => {
        if (ms <= 0) return t("stakeCard.timeLeft.completed");

        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / (1000 * 60)) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));

        return t("stakeCard.timeLeft.format", { days, hours, minutes, seconds });
    };

    useEffect(() => {
        const updateProgress = () => {
            const now = Math.floor(Date.now() / 1000);
            const elapsed = now - stake.startTime;
            const duration = stake.endTime - stake.startTime;
            const timeRemaining = stake.endTime - now;

            const isCompleted = now >= stake.endTime && !stake.isWithdrawn;
            setIsUnstakeable(isCompleted);

            // Progress ve time left güncelleme
            const currentProgress = Math.min((elapsed / duration) * 100, 100);
            setProgress(currentProgress);
            setTimeLeft(formatDuration(timeRemaining * 1000));

            // Toplam kazanç hesaplama - gerçek zamanlı değil
            const totalReward = calculateTotalReward();
            setEarnings(totalReward.toFixed(4));
        };

        updateProgress();
        const timer = setInterval(updateProgress, 1000);
        return () => clearInterval(timer);
    }, [stake, stakeInfo, t, calculateTotalReward]);

    return (
        <div
            className="bg-white dark:bg-gray-800 rounded-3xl shadow-lg p-6 border border-gray-100 dark:border-gray-700 
                     transition-all duration-300 transform hover:-translate-y-1 hover:shadow-2xl"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {/* Header with Glass Effect */}
            <div className="flex justify-between items-center mb-6 bg-gray-50 dark:bg-gray-700/30 p-4 rounded-2xl backdrop-blur-sm">
                <div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-1 flex items-center">
                        Stake #{stake.id.toString()}
                        <ArrowUpRight 
                            size={16} 
                            className={`ml-2 transition-all duration-300 ${isHovered ? 'text-bnb-yellow' : 'text-gray-400'}`}
                        />
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
                        <Timer size={14} className="mr-1" />
                        {new Date(stake.startTime * 1000).toLocaleDateString()}
                    </p>
                </div>
                <div className={`
                    px-4 py-2 rounded-xl text-sm font-medium transition-colors duration-300
                    ${isUnstakeable
                        ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300"
                        : stake.isWithdrawn
                        ? "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
                        : "bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300"
                    }
                `}>
                    {stake.isWithdrawn 
                        ? t("stakeCard.withdrawn")
                        : isUnstakeable
                        ? t("stakeCard.readyToUnstake")
                        : t("stakeCard.locked")}
                </div>
            </div>

            {/* Stats Grid with Hover Effects */}
            <div className="grid grid-cols-2 gap-4 mb-6">
                {[
                    {
                        icon: <Coins size={18} />,
                        label: t("stakeCard.amount"),
                        value: `${stakeInfo.amount} ${stakeInfo.token.symbol}`,
                        color: "text-blue-600 dark:text-blue-400"
                    },
                    {
                        icon: <Clock size={18} />,
                        label: t("stakeCard.duration"),
                        value: stakeInfo.periodLabel,
                        color: "text-purple-600 dark:text-purple-400"
                    },
                    {
                        icon: <TrendingUp size={18} />,
                        label: t("stakeCard.apr"),
                        value: `${stakeInfo.apr}%`,
                        color: "text-bnb-yellow"
                    },
                    {
                        icon: <Coins size={18} />,
                        label: t("stakeCard.earnings"),
                        value: `${parseFloat(earnings).toFixed(4)} ${stakeInfo.token.symbol}`,
                        color: "text-green-500"
                    }
                ].map((stat, index) => (
                    <div 
                        key={index}
                        className="p-4 bg-gray-50 dark:bg-gray-700/30 rounded-xl hover:bg-gray-100 
                                 dark:hover:bg-gray-700/50 transition-all duration-300"
                    >
                        <div className="flex items-center text-gray-500 dark:text-gray-400 text-sm mb-2">
                            <span className="mr-2">{stat.icon}</span>
                            {stat.label}
                        </div>
                        <p className={`text-lg font-bold ${stat.color}`}>
                            {stat.value}
                        </p>
                    </div>
                ))}
            </div>

            {/* Progress Bar with Animation */}
            <div className="mb-6">
                <div className="h-3 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
                    <div
                        className="h-full bg-gradient-to-r from-bnb-yellow via-yellow-500 to-yellow-400 
                                 transition-all duration-1000 ease-out"
                        style={{ 
                            width: `${progress}%`,
                            boxShadow: '0 0 10px rgba(234, 179, 8, 0.3)'
                        }}
                    />
                </div>
                <div className="mt-3 flex justify-between text-sm">
                    <span className="text-gray-500 dark:text-gray-400">
                        {t("stakeCard.timeLeft.label")}
                    </span>
                    <span className="text-gray-900 dark:text-white font-medium">
                        {timeLeft}
                    </span>
                </div>
            </div>

            {/* Unstake Button with Enhanced Animation */}
            {!stake.isWithdrawn && (
                <button
                    onClick={() => onUnstake(stake.id)}
                    disabled={!isUnstakeable || isLoading || stake.isWithdrawn}
                    className={`
                        w-full py-4 px-6 rounded-xl font-semibold text-base
                        transition-all duration-300 transform
                        ${isUnstakeable && !isLoading && !stake.isWithdrawn
                            ? "bg-gradient-to-r from-bnb-yellow to-yellow-400 text-black shadow-lg hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0"
                            : "bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed"
                        }
                    `}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center">
                            <div className="animate-spin rounded-full h-5 w-5 border-2 border-black border-t-transparent mr-2" />
                            {t("stakeCard.processing")}
                        </div>
                    ) : (
                        t("stakeCard.unstake")
                    )}
                </button>
            )}
        </div>
    );
};

export default StakeCard;