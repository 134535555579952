import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const HeroSection = () => {
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [isDark, setIsDark] = useState(document.documentElement.classList.contains('dark'));

  // Video URLs for different themes
  const videos = {
    dark: "https://videos.pexels.com/video-files/3129977/3129977-uhd_2560_1440_30fps.mp4",
    light: "https://videos.pexels.com/video-files/3129977/3129977-uhd_2560_1440_30fps.mp4"
  };

  useEffect(() => {
    // Dark mode değişikliklerini izle
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsDark(document.documentElement.classList.contains('dark'));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Video kaynağını temaya göre güncelle
      video.src = isDark ? videos.dark : videos.light;

      video.setAttribute('playsinline', '');
      const handleLoadedData = () => {
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(() => {
            const handleClick = () => {
              video.play();
              document.removeEventListener('click', handleClick);
            };
            document.addEventListener('click', handleClick);
          });
        }
      };
      video.addEventListener('loadeddata', handleLoadedData);
      return () => {
        video.removeEventListener('loadeddata', handleLoadedData);
      };
    }
  }, [isDark]);

  const handleStakeClick = () => {
    if (!isConnected) {
      openConnectModal();
    } else {
      window.location.href = '#stake';
    }
  };

  return (
    <section id="home" className="relative h-screen flex items-center justify-center overflow-hidden">
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        preload="auto"
        className="absolute z-10 w-auto min-w-full min-h-full max-w-none object-cover"
      >
        {t('video.notSupported')}
      </video>
      <div className="absolute inset-0 bg-bnb-dark dark:bg-black bg-opacity-30 dark:bg-opacity-50 z-15" />
      <div className="z-20 text-bnb-yellow dark:text-white text-center relative px-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
          {t('hero.title')}
        </h1>
        <p className="text-lg md:text-xl mb-8">
          {t('hero.subtitle')}
        </p>
        <button 
          onClick={handleStakeClick}
          className="bg-bnb-yellow hover:bg-blue-100 text-bnb-dark font-bold py-3 px-8 rounded-full 
                   transition duration-300 ease-in-out transform hover:scale-105 shadow-lg text-lg inline-block"
        >
          {t('hero.stakeButton')}
        </button>
      </div>
    </section>
  );
};

export default HeroSection;