import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useMotionValue, useTransform, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Shield, TrendingUp, Settings, Check, Users, Clock } from 'lucide-react';

const FeatureCard = ({ icon: Icon, titleKey, descriptionKey, stats, index }) => {
  const { t } = useTranslation();
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  const handleMouseMove = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    const xPct = (mouseX / width - 0.5) * 2;
    const yPct = (mouseY / height - 0.5) * 2;
    x.set(xPct * 100);
    y.set(yPct * 100);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        rotateX,
        rotateY,
        transformStyle: "preserve-3d",
      }}
      className="relative group"
    >
      <div className="relative p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl 
                    transform transition-all duration-300 group-hover:shadow-2xl
                    border border-gray-100 dark:border-gray-700 overflow-hidden">
        {/* Gradient Background */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white dark:from-gray-800 dark:to-gray-700 opacity-80" />

        {/* Pattern Overlay */}
        <div className="absolute inset-0 opacity-[0.03] dark:opacity-[0.05]">
          <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid" width="20" height="20" patternUnits="userSpaceOnUse">
                <path d="M 20 0 L 0 0 0 20" fill="none" stroke="currentColor" strokeWidth="0.5"/>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>

        {/* Content */}
        <div className="relative z-10">
          <div className="flex justify-center mb-6">
            <div className="p-4 bg-gradient-to-br from-bnb-yellow/10 to-yellow-500/10 rounded-2xl">
              <Icon size={40} className="text-bnb-yellow transform transition-transform group-hover:scale-110" />
            </div>
          </div>

          <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white text-center">
            {t(titleKey)}
          </h3>

          <p className="text-gray-600 dark:text-gray-300 text-center mb-6">
            {t(descriptionKey)}
          </p>

          {/* Stats */}
          {stats && (
            <div className="grid grid-cols-2 gap-4 mt-6 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl">
              {stats.map((stat, i) => (
                <div key={i} className="text-center">
                  <div className="text-2xl font-bold text-bnb-yellow">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {stat.label}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Hover Effect Lines */}
          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            <div className="absolute top-0 left-0 w-20 h-20 border-t-2 border-l-2 border-bnb-yellow" />
            <div className="absolute bottom-0 right-0 w-20 h-20 border-b-2 border-r-2 border-bnb-yellow" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const features = [
    {
      icon: Shield,
      titleKey: 'whyChooseUs.security.title',
      descriptionKey: 'whyChooseUs.security.description',
      stats: [
        { value: '100%', label: 'Secure' },
        { value: '24/7', label: 'Monitoring' }
      ]
    },
    {
      icon: TrendingUp,
      titleKey: 'whyChooseUs.highProfit.title',
      descriptionKey: 'whyChooseUs.highProfit.description',
      stats: [
        { value: 'Up to 50%', label: 'APR' },
        { value: 'Daily', label: 'Rewards' }
      ]
    },
    {
      icon: Settings,
      titleKey: 'whyChooseUs.easyToUse.title',
      descriptionKey: 'whyChooseUs.easyToUse.description',
      stats: [
        { value: '< 1min', label: 'to Start' },
        { value: '0 Fee', label: 'to Stake' }
      ]
    }
  ];

  return (
    <section 
      ref={ref} 
      className="py-24 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 relative overflow-hidden"
    >
      {/* Background Decoration */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-1/2 -right-1/4 w-96 h-96 bg-bnb-yellow opacity-[0.03] rounded-full blur-3xl" />
        <div className="absolute -bottom-1/2 -left-1/4 w-96 h-96 bg-yellow-500 opacity-[0.03] rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 }
          }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-bnb-yellow to-yellow-500">
            {t('whyChooseUs.title')}
          </h2>
          <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t('whyChooseUs.subtitle')}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </div>

        {/* Bottom Stats */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 }
          }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8 text-center"
        >
          <div className="p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
            <Users className="w-8 h-8 text-bnb-yellow mx-auto mb-4" />
            <div className="text-3xl font-bold text-gray-900 dark:text-white mb-2">10,000+</div>
            <div className="text-gray-600 dark:text-gray-400">Active Users</div>
          </div>
          <div className="p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
            <TrendingUp className="w-8 h-8 text-bnb-yellow mx-auto mb-4" />
            <div className="text-3xl font-bold text-gray-900 dark:text-white mb-2">$5M+</div>
            <div className="text-gray-600 dark:text-gray-400">Total Value Locked</div>
          </div>
          <div className="p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
            <Clock className="w-8 h-8 text-bnb-yellow mx-auto mb-4" />
            <div className="text-3xl font-bold text-gray-900 dark:text-white mb-2">365 Days</div>
            <div className="text-gray-600 dark:text-gray-400">Platform Running</div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyChooseUs;