// ReferralPage.jsx
import React from 'react';
import ReferralDashboard from './ReferralDashboard';

const ReferralPage = () => {
    return ( 
        
        <div className="min-h-screen py-20 bg-gray-50 dark:bg-gray-900">
            <ReferralDashboard />
        </div>
    );
};

export default ReferralPage;