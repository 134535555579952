import { useState, useEffect, useCallback } from 'react';
import { useAccount, useContractRead, useContractWrite, useWalletClient, usePublicClient } from 'wagmi';
import { useAlert } from '../providers/AlertProvider';
import { 
    STAKING_ADDRESS, 
    SUPPORTED_TOKENS,
    STAKING_PERIODS,
    calculateRewards,
    validateStakeAmount,
    getTokenByAddress,
    getPeriodLabel
} from '../web3/config';
import { parseEther, formatEther } from 'viem';
import { STAKING_ABI, TOKEN_ABI } from '../web3/contractABI';

export const useStaking = () => {
    const { address, isConnected } = useAccount();
    const { data: walletClient } = useWalletClient();
    const publicClient = usePublicClient();
    const { showAlert } = useAlert();
    const [stakes, setStakes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Referral bilgilerini oku
    const { data: referralInfo } = useContractRead({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'getReferralInfo',
        args: [address],
        enabled: !!address && isConnected,
        watch: true
    });

    // Contract read operation for stakes
    const { data: stakesData, refetch: refetchStakes } = useContractRead({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'getUserStakes',
        args: [address],
        enabled: !!address && isConnected,
        watch: true,
        onSuccess: (data) => {
            console.log('getUserStakes success:', {
                address,
                data
            });
        },
        onError: (error) => {
            console.error('getUserStakes error:', error);
        }
    });

    // Contract write operations
    const { writeAsync: createStake } = useContractWrite({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'createStake',
    });

    const { writeAsync: approveToken } = useContractWrite({
        abi: TOKEN_ABI,
        functionName: 'approve',
    });

    // URL'den ve localStorage'dan referral'ı alma fonksiyonu
    const getPendingReferral = useCallback(() => {
        if (typeof window !== 'undefined') {
            // İlk önce localStorage'a kaydedilen referral'ı kontrol et
            const storedReferral = localStorage.getItem('pendingReferral');
            if (storedReferral) {
                console.log('Found stored referral:', storedReferral);
                return storedReferral;
            }

            // URL'den referral parametresini kontrol et
            const urlParams = new URLSearchParams(window.location.search);
            const urlReferral = urlParams.get('ref');
            if (urlReferral) {
                console.log('Found URL referral:', urlReferral);
                localStorage.setItem('pendingReferral', urlReferral);
                return urlReferral;
            }
        }
        return null;
    }, []);

    // Process stakes data
    const processStakes = useCallback((data) => {
        console.log('Processing stakes:', {
            rawData: data,
            address,
            isConnected
        });

        if (!data) return [];

        return data.map((stake, index) => {
            console.log('Processing individual stake:', stake);
            const token = getTokenByAddress(stake.tokenAddress);
            console.log('Found token:', token);

            if (!token) {
                console.log('No token found for address:', stake.tokenAddress);
                return null;
            }

            try {
                const formattedStake = {
                    id: index,
                    amount: formatEther(stake.amount),
                    startTime: Number(stake.startTime),
                    endTime: Number(stake.endTime),
                    apr: Number(stake.apr),
                    isWithdrawn: stake.isWithdrawn,
                    token: token.symbol,
                    tokenAddress: stake.tokenAddress,
                    duration: Number(stake.endTime) - Number(stake.startTime),
                    periodLabel: getPeriodLabel(Number(stake.endTime) - Number(stake.startTime)),
                    canWithdraw: !stake.isWithdrawn && Date.now() >= Number(stake.endTime),
                    rewards: stake.rewards ? formatEther(stake.rewards) : '0'
                };

                console.log('Formatted stake:', formattedStake);
                return formattedStake;
            } catch (error) {
                console.error('Error formatting stake:', error);
                return null;
            }
        }).filter(Boolean);
    }, [address, isConnected]);

    // Manual fetch function
    const fetchStakesManually = async () => {
        if (!address || !publicClient) return;

        try {
            console.log('Manually fetching stakes for address:', address);
            const data = await publicClient.readContract({
                address: STAKING_ADDRESS,
                abi: STAKING_ABI,
                functionName: 'getUserStakes',
                args: [address]
            });
            console.log('Manual fetch result:', data);

            if (data) {
                const processedStakes = processStakes(data);
                setStakes(processedStakes);
            }
        } catch (error) {
            console.error('Manual fetch error:', error);
        }
    };

    // Effect for processing stakes data
    useEffect(() => {
        console.log('Stakes data changed:', stakesData);
        if (stakesData) {
            const processedStakes = processStakes(stakesData);
            console.log('Setting processed stakes:', processedStakes);
            setStakes(processedStakes);
        }
    }, [stakesData, processStakes]);

    // Effect for initial and connection-based fetch
    useEffect(() => {
        if (address && isConnected) {
            console.log('Fetching stakes for new connection');
            fetchStakesManually();
        }
    }, [address, isConnected]);

    // Stake function
    const stake = async (amount, token, duration, referrer = null) => {
        if (!isConnected || !address || !walletClient) {
            showAlert('error', 'Please connect your wallet');
            return false;
        }

        const validation = validateStakeAmount(amount, token, duration);
        if (!validation.isValid) {
            showAlert('error', validation.error);
            return false;
        }

        setIsLoading(true);
        try {
            const tokenConfig = SUPPORTED_TOKENS[token];
            const parsedAmount = parseEther(amount);

            // Referral kontrolü - öncelikle parametre olarak gelen, yoksa pending referral'ı kullan
            const actualReferrer = referrer || getPendingReferral();

            console.log('Stake process starting with referrer:', actualReferrer);

            if (actualReferrer) {
                if (actualReferrer === address) {
                    showAlert('error', 'Cannot use self as referrer');
                    return false;
                }
                console.log('Using referral address:', actualReferrer);
            }

            if (!tokenConfig.isNative) {
                showAlert('info', 'Please approve token spending...');

                const { request: approveRequest } = await publicClient.simulateContract({
                    account: address,
                    address: tokenConfig.address,
                    abi: TOKEN_ABI,
                    functionName: 'approve',
                    args: [STAKING_ADDRESS, parsedAmount],
                });

                const approveTx = await walletClient.writeContract(approveRequest);
                showAlert('info', 'Waiting for approval confirmation...');

                await publicClient.waitForTransactionReceipt({ hash: approveTx });
                showAlert('success', 'Token approved successfully!');
            }

            showAlert('info', 'Please confirm the staking transaction...');

            const { request } = await publicClient.simulateContract({
                account: address,
                address: STAKING_ADDRESS,
                abi: STAKING_ABI,
                functionName: 'createStake',
                args: [
                    tokenConfig.address,
                    parsedAmount,
                    duration,
                    actualReferrer || '0x0000000000000000000000000000000000000000'
                ],
                value: tokenConfig.isNative ? parsedAmount : BigInt(0)
            });

            console.log('Stake transaction request:', request);

            const hash = await walletClient.writeContract(request);
            showAlert('success', 'Transaction submitted!');

            await publicClient.waitForTransactionReceipt({ hash });
            showAlert('success', 'Stake successful!');

            // Başarılı stake sonrası pending referral'ı temizle
            if (actualReferrer) {
                localStorage.removeItem('pendingReferral');
                console.log('Cleared pending referral after successful stake');
            }

            // Fetch updated stakes
            await new Promise(resolve => setTimeout(resolve, 2000));
            await fetchStakesManually();
            refetchStakes();

            return true;
        } catch (error) {
            console.error('Staking error:', error);
            showAlert('error', error.message);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    // Withdraw function
    const withdraw = async (stakeId) => {
        if (!isConnected || !address || !walletClient) {
            showAlert('error', 'Please connect your wallet');
            return false;
        }

        setIsLoading(true);
        try {
            const { request } = await publicClient.simulateContract({
                account: address,
                address: STAKING_ADDRESS,
                abi: STAKING_ABI,
                functionName: 'withdrawStake',
                args: [stakeId]
            });

            const hash = await walletClient.writeContract(request);
            showAlert('success', 'Withdrawal transaction submitted!');

            await publicClient.waitForTransactionReceipt({ hash });
            showAlert('success', 'Withdrawal successful!');

            // Fetch updated stakes
            await new Promise(resolve => setTimeout(resolve, 2000));
            await fetchStakesManually();
            refetchStakes();

            return true;
        } catch (error) {
            console.error('Withdraw error:', error);
            showAlert('error', error.message);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        stakes,
        isLoading,
        stake,
        withdraw,
        refetchStakes: fetchStakesManually,
        referralInfo,
        getPendingReferral
    };
};

export default useStaking;