// ReferralDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useAccount, useContractRead, useBalance } from 'wagmi';
import { STAKING_ADDRESS } from '../web3/config';
import { STAKING_ABI } from '../web3/contractABI';
import { formatEther } from 'viem';
import { Link, Copy, CheckCircle2, ExternalLink } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { Users, LineChart, Coins, Gift } from 'lucide-react';

const ReferralDashboard = () => {
    const { address } = useAccount();
    const [copySuccess, setCopySuccess] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    // Wallet balance
    const { data: balance } = useBalance({
        address: address,
    });

    // Referral bilgilerini oku
    const { data: referralInfo, isError: referralError, isLoading: referralLoading } = useContractRead({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'getReferralInfo',
        args: [address],
        watch: true,
    });

    // Referral yüzdesini oku
    const { data: referralPercent } = useContractRead({
        address: STAKING_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'referralPercent',
        watch: true,
    });

    const handleCopyLink = async () => {
        const referralLink = `${window.location.origin}?ref=${address}`;
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopySuccess(true);
            toast.success('Referral link copied to clipboard!');
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (err) {
            toast.error('Failed to copy referral link');
            console.error('Failed to copy:', err);
        }
    };

    const shortenAddress = (addr) => {
        if (!addr) return '';
        return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
    };

    const formatAmount = (amount, decimals = 4) => {
        if (!amount) return '0';
        return parseFloat(formatEther(amount)).toFixed(decimals);
    };

    if (!address) {
        return ( 
            <div className="flex items-center justify-center min-h-[400px] bg-white dark:bg-gray-800 rounded-3xl shadow-xl">
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                        Connect Wallet
                    </h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        Please connect your wallet to see referral information
                    </p>
                </div>
            </div>
        );
    }

    if (referralLoading) {
        return (
            <div id="referral" className="flex items-center justify-center min-h-[400px] bg-white dark:bg-gray-800 rounded-3xl shadow-xl">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-bnb-yellow"></div>
            </div>
        );
    }

    if (referralError) {
        return (
            <div className="flex items-center justify-center min-h-[400px] bg-white dark:bg-gray-800 rounded-3xl shadow-xl">
                <div className="text-center text-red-500">
                    Error loading referral data. Please try again.
                </div>
            </div>
        );
    }

    const referralLink = `${window.location.origin}?ref=${address}`;

    return (
        <div className="max-w-7xl mx-auto p-6">
            <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-xl p-8">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Referral Dashboard
                    </h1>
                    <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
                        Share your referral link and earn {Number(referralPercent) / 100}% of your referrals' stake amounts!
                    </p>
                </div>

                {/* Referral Link Section */}
                <div className="mb-12 p-6 bg-gradient-to-r from-yellow-50 to-orange-50 dark:from-gray-700/30 dark:to-gray-700/20 rounded-2xl border border-yellow-100 dark:border-gray-600">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-white flex items-center gap-2">
                        <Link className="w-5 h-5" />
                        Your Referral Link
                    </h2>
                    <div className="flex items-center gap-4">
                        <div className="flex-1 bg-white dark:bg-gray-700 rounded-xl border border-gray-200 dark:border-gray-600 overflow-hidden">
                            <input
                                type="text"
                                value={referralLink}
                                readOnly
                                className="w-full px-4 py-3 bg-transparent border-none focus:outline-none text-gray-900 dark:text-white"
                            />
                        </div>
                        <button
                            onClick={handleCopyLink}
                            className={`px-6 py-3 rounded-xl font-semibold transition-all duration-300 flex items-center gap-2
                                ${copySuccess
                                    ? 'bg-green-500 text-white'
                                    : 'bg-bnb-yellow hover:bg-yellow-400 text-black'
                                }`}
                        >
                            {copySuccess ? (
                                <>
                                    <CheckCircle2 className="w-5 h-5" />
                                    Copied!
                                </>
                            ) : (
                                <>
                                    <Copy className="w-5 h-5" />
                                    Copy
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                    <StatsCard
                        title="Total Referrals"
                        value={referralInfo ? Number(referralInfo[1]) : 0}
                        tooltip="Total number of users who used your referral link"
                        icon={<Users className="w-5 h-5" />}
                    />
                    <StatsCard
                        title="Total Volume"
                        value={`${formatAmount(referralInfo ? referralInfo[4] : 0)} BNB`}
                        tooltip="Total amount staked using your referral link"
                        icon={<LineChart className="w-5 h-5" />}
                    />
                    <StatsCard
                        title="Total Rewards"
                        value={`${formatAmount(referralInfo ? referralInfo[2] : 0)} BNB`}
                        tooltip="Total rewards earned from referrals"
                        icon={<Coins className="w-5 h-5" />}
                    />
                </div>

                {/* Referred Users Table */}
                {referralInfo && referralInfo[3].length > 0 && (
                    <div className="overflow-hidden rounded-2xl border border-gray-200 dark:border-gray-700">
                        <div className="bg-gray-50 dark:bg-gray-700/50 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                Referred Users
                            </h3>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                <thead className="bg-gray-50 dark:bg-gray-700">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            User Address
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            BSC Scan
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                    {referralInfo[3].map((user, index) => (
                                        <tr key={user} className="hover:bg-gray-50 dark:hover:bg-gray-700/50">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                                {shortenAddress(user)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                                <a
                                                    href={`https://bscscan.com/address/${user}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-bnb-yellow hover:text-yellow-400 flex items-center gap-1"
                                                >
                                                    View on BSCScan
                                                    <ExternalLink className="w-4 h-4" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Empty State */}
                {referralInfo && referralInfo[3].length === 0 && (
                    <div className="text-center py-12 bg-gray-50 dark:bg-gray-700/30 rounded-2xl">
                        <div className="mb-4">
                            <Gift className="w-12 h-12 text-gray-400 dark:text-gray-500 mx-auto" />
                        </div>
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                            No Referrals Yet
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Share your referral link to start earning rewards!
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

const StatsCard = ({ title, value, tooltip, icon }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="relative p-6 bg-white dark:bg-gray-700/30 rounded-2xl border border-gray-100 dark:border-gray-600 transition-all duration-300 hover:shadow-lg"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div className="flex items-center justify-between mb-2">
                <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</h4>
                {icon && <span className="text-gray-400 dark:text-gray-500">{icon}</span>}
            </div>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">{value}</p>

            {/* Tooltip */}
            {tooltip && showTooltip && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg whitespace-nowrap z-10">
                    {tooltip}
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
                </div>
            )}
        </div>
    );
};

export default ReferralDashboard;