import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { motion, AnimatePresence } from 'framer-motion';
import { useStaking } from '../../hooks/useStaking';
import { 
    SUPPORTED_TOKENS,
    STAKING_PERIODS,
    validateStakeAmount,
    calculateRewards,
    TOKEN_CATEGORIES
} from '../../web3/config';
import TokenSelector from '../TokenSelector';
import CategorySelector from '../TokenSelector/CategorySelector';
import SearchBar from '../TokenSelector/SearchBar';
import { ArrowDown, Clock, Coins } from 'lucide-react';

const StakingSection = () => {
    const { t } = useTranslation();
    const { address } = useAccount();
    const { stake, isLoading } = useStaking();

    const [amount, setAmount] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [selectedToken, setSelectedToken] = useState('BNB');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');

    // APR ve kazanç hesaplama
    const calculateAPY = () => {
        if (!amount || !selectedPeriod) return { apr: 0, estimatedEarnings: 0 };

        const token = SUPPORTED_TOKENS[selectedToken];
        const apr = token.apr[selectedPeriod];
        const earnings = calculateRewards(amount, apr, selectedPeriod);

        return { apr, earnings };
    };

    const handleStake = async () => {
        if (!address || !amount || !selectedPeriod) return;

        const urlParams = new URLSearchParams(window.location.search);
        const referrer = urlParams.get('ref');

        const success = await stake(amount, selectedToken, selectedPeriod, referrer);
        if (success) {
            setAmount('');
            setSelectedPeriod(null);
        }
    };

    const { apr, earnings } = calculateAPY();

    return (
        <section id="stake" className="py-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="container mx-auto px-4 max-w-6xl"
            >
                <h2 className="text-5xl font-bold text-center mb-4 dark:text-white bg-clip-text text-transparent bg-gradient-to-r from-bnb-yellow to-yellow-500">
                    {t('staking.title')}
                </h2>
                <p className="text-center text-gray-600 dark:text-gray-400 mb-12 max-w-2xl mx-auto">
                    {t('staking.subtitle')}
                </p>

                <motion.div 
                    className="bg-white dark:bg-gray-800 rounded-3xl shadow-xl p-8 mb-12"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    {/* Token Selector */}
                    <div className="mb-8 space-y-4">
                        <div className="flex items-center justify-between">
                     
                           
                           
                        </div>
                       

                        <TokenSelector
                            selectedToken={selectedToken}
                            onSelectToken={setSelectedToken}
                            amount={amount}
                            duration={selectedPeriod}
                            searchQuery={searchQuery}
                            selectedCategory={selectedCategory}
                        />
                    </div>

                    {/* Amount Input */}
                    <div className="mb-8">
                        <label className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-4 block">
                            {t('staking.amount')}
                        </label>
                        <div className="relative">
                            <input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder={t('staking.amountPlaceholder')}
                                className="w-full px-6 py-4 text-lg border border-gray-200 dark:border-gray-700 rounded-2xl 
                                         bg-gray-50 dark:bg-gray-700/50 focus:ring-2 focus:ring-bnb-yellow focus:border-transparent
                                         dark:text-white transition-all duration-200"
                            />
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => {/* MAX logic */}}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 px-4 py-2 
                                         text-sm font-medium bg-bnb-yellow text-black rounded-xl 
                                         hover:bg-yellow-400 transition-all duration-200"
                            >
                                MAX
                            </motion.button>
                        </div>
                    </div>

                    {/* Period Selection */}
                    <div className="mb-8">
                        <label className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-4 block">
                            {t('staking.duration')}
                        </label>
                        <div className="grid grid-cols-2 gap-6">
                            {Object.entries(STAKING_PERIODS).map(([key, period]) => {
                                const apr = SUPPORTED_TOKENS[selectedToken]?.apr[period];
                                return (
                                    <motion.button
                                        key={key}
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        onClick={() => setSelectedPeriod(period)}
                                        className={`flex flex-col items-center justify-center p-6 rounded-2xl border-2 transition-all
                                                ${selectedPeriod === period
                                                    ? 'border-bnb-yellow bg-gradient-to-br from-yellow-50 to-orange-50 dark:from-yellow-900/20 dark:to-orange-900/20'
                                                    : 'border-gray-200 dark:border-gray-700'
                                                } hover:border-bnb-yellow group`}
                                    >
                                        <Clock className={`w-6 h-6 mb-2 ${selectedPeriod === period ? 'text-bnb-yellow' : 'text-gray-400'}`} />
                                        <span className="text-xl font-bold dark:text-white mb-2">
                                            {key === 'MONTHLY' ? '30 Days' : '365 Days'}
                                        </span>
                                        <span className={`text-lg font-semibold ${selectedPeriod === period ? 'text-bnb-yellow' : 'text-gray-500 dark:text-gray-400'}`}>
                                            APR: {apr}%
                                        </span>
                                    </motion.button>
                                );
                            })}
                        </div>
                    </div>

                    {/* Estimated Earnings */}
                    <AnimatePresence>
                        {selectedPeriod && amount && (
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                className="bg-gradient-to-r from-yellow-50 to-orange-50 dark:from-yellow-900/20 dark:to-orange-900/20 
                                         rounded-2xl p-6 mb-8 border border-yellow-200 dark:border-yellow-900/30"
                            >
                                <div className="flex items-center justify-between">
                                    <div>
                                        <p className="text-gray-600 dark:text-gray-400 mb-1">
                                            {t('staking.estimatedEarnings')}
                                        </p>
                                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                                            {earnings.toFixed(4)} {selectedToken}
                                        </p>
                                    </div>
                                    <div className="text-right">
                                        <p className="text-gray-600 dark:text-gray-400 mb-1">APR</p>
                                        <p className="text-2xl font-bold text-bnb-yellow">{apr}%</p>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* Stake Button */}
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleStake}
                        disabled={!address || !amount || !selectedPeriod || isLoading}
                        className="w-full bg-gradient-to-r from-bnb-yellow to-yellow-500 text-black font-bold py-4 px-6
                                 rounded-xl transition duration-300 ease-in-out transform shadow-lg hover:shadow-xl
                                 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 text-lg"
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-6 w-6 border-2 border-black border-t-transparent mr-2" />
                                {t('common.processing')}
                            </div>
                        ) : address ? (
                            t('staking.stakeNow')
                        ) : (
                            t('staking.connectWallet')
                        )}
                    </motion.button>
                </motion.div>

                {/* Chart Section */}
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="bg-white dark:bg-gray-800 rounded-3xl shadow-xl overflow-hidden"
                >
                    <div className="border-b border-gray-200 dark:border-gray-700">
              
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default StakingSection;