// components/ui/Alert.jsx
import React from 'react';
import { X, CheckCircle, XCircle, AlertCircle, Info } from 'lucide-react';

const Alert = ({ isOpen, type = 'info', title, message, onClose }) => {
  if (!isOpen) return null;

  const alertStyles = {
    success: {
      bg: 'bg-green-50 dark:bg-green-900/20',
      border: 'border-green-400 dark:border-green-500',
      text: 'text-green-800 dark:text-green-300',
      icon: CheckCircle,
      iconColor: 'text-green-500'
    },
    error: {
      bg: 'bg-red-50 dark:bg-red-900/20',
      border: 'border-red-400 dark:border-red-500',
      text: 'text-red-800 dark:text-red-300',
      icon: XCircle,
      iconColor: 'text-red-500'
    },
    warning: {
      bg: 'bg-yellow-50 dark:bg-yellow-900/20',
      border: 'border-yellow-400 dark:border-yellow-500',
      text: 'text-yellow-800 dark:text-yellow-300',
      icon: AlertCircle,
      iconColor: 'text-yellow-500'
    },
    info: {
      bg: 'bg-blue-50 dark:bg-blue-900/20',
      border: 'border-blue-400 dark:border-blue-500',
      text: 'text-blue-800 dark:text-blue-300',
      icon: Info,
      iconColor: 'text-blue-500'
    }
  };

  const style = alertStyles[type];
  const Icon = style.icon;

  return (
    <div className="fixed bottom-4 right-4 z-50 animate-slide-up">
      <div className={`max-w-md p-4 rounded-xl border ${style.bg} ${style.border}`}>
        <div className="flex items-start">
          <Icon className={`w-5 h-5 ${style.iconColor} mt-0.5`} />
          <div className="ml-3 w-full">
            <h3 className={`text-sm font-medium ${style.text}`}>
              {title}
            </h3>
            {message && (
              <div className={`mt-1 text-sm ${style.text}`}>
                {message}
              </div>
            )}
          </div>
          <button
            onClick={onClose}
            className={`ml-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex ${style.text} hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${type}-50 focus:ring-${type}-600`}
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;