import React, { useEffect } from 'react';
import { useStaking } from '../../hooks/useStaking';
import StakeCard from '../ui/StakeCard';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useTranslation } from 'react-i18next';
import { STAKING_PERIODS, getTokenByAddress } from '../../web3/config';

const ActiveStakes = () => {
  const { t } = useTranslation();
  const { stakes, isLoading } = useStaking();
  const { address } = useAccount();

  // Debug logs
  useEffect(() => {
    console.log('Address:', address);
    console.log('All stakes:', stakes);
  }, [address, stakes]);

  const activeStakes = stakes.filter(stake => !stake.isWithdrawn);

  useEffect(() => {
    if (stakes.length > 0) {
      console.log('Current stakes:', stakes);
      console.log('Active stakes:', stakes.filter(s => !s.isWithdrawn));
    }
  }, [stakes]);

  // Calculate total earning for a stake
  const calculateTotalEarning = (stake) => {
    const stakeAmount = parseFloat(stake.amount);
    const token = getTokenByAddress(stake.tokenAddress);
    const duration = stake.endTime - stake.startTime;
    const period = duration === STAKING_PERIODS.MONTHLY ? STAKING_PERIODS.MONTHLY : STAKING_PERIODS.YEARLY;
    const apr = token.apr[period];
    const durationInDays = duration / (24 * 60 * 60);
    return stakeAmount * (apr / 100) * (durationInDays / 365);
  };

  // Token bazlı stake'leri grupla
  const tokenStakes = stakes.reduce((acc, stake) => {
    console.log('Processing stake:', stake);
    if (!stake.isWithdrawn) {
      if (!acc[stake.token]) {
        acc[stake.token] = [];
      }
      acc[stake.token].push(stake);
    }
    return acc;
  }, {});

  console.log('Grouped stakes by token:', tokenStakes);

  if (!address) {
    return (
      <section id="active-stakes" className="py-20 bg-white dark:bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12 dark:text-white">
            {t('activeStakes.title')}
          </h2>
          <div className="max-w-md mx-auto text-center">
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              {t('activeStakes.connectWallet')}
            </p>
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="activeStakes" className="py-20 bg-white dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 dark:text-white">
          {t('activeStakes.title')}
        </h2>

        {Object.keys(tokenStakes).length === 0 ? (
          <div className="bg-gray-100 dark:bg-gray-700 rounded-lg shadow-xl p-6 sm:p-8 max-w-2xl mx-auto">
            <p className="text-center text-gray-500 dark:text-gray-400">
              {t('activeStakes.noStakes')}
            </p>
          </div>
        ) : (
          <>
            {Object.entries(tokenStakes).map(([tokenSymbol, tokenStakeList]) => (
              <div key={tokenSymbol} className="mb-12">
                <h3 className="text-2xl font-bold mb-6 dark:text-white">
                  {tokenSymbol} Stakes
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {tokenStakeList.map((stake) => (
                    <StakeCard
                      key={stake.id}
                      stake={stake}
                      isLoading={isLoading}
                    />
                  ))}
                </div>

                {/* Token Stats */}
                <div className="mt-6 p-6 bg-gray-100 dark:bg-gray-700 rounded-lg">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-center">
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                        Total Staked
                      </div>
                      <div className="text-lg font-bold text-bnb-yellow">
                        {tokenStakeList.reduce((sum, s) => sum + parseFloat(s.amount), 0).toFixed(4)} {tokenSymbol}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                        Active Stakes
                      </div>
                      <div className="text-lg font-bold text-bnb-yellow">
                        {tokenStakeList.length}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                        Total Earnings
                      </div>
                      <div className="text-lg font-bold text-bnb-yellow">
                        {tokenStakeList.reduce((sum, s) => {
                          const totalEarning = calculateTotalEarning(s);
                          return sum + (isNaN(totalEarning) ? 0 : totalEarning);
                        }, 0).toFixed(4)} {tokenSymbol}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default ActiveStakes;