import { bsc, bscTestnet } from 'wagmi/chains';

// Contract adresi
export const STAKING_ADDRESS = '0x6f7EBc5a13b8A4a71824808dFe25165Be0a3d75F';

// Stake süreleri (saniye cinsinden)
export const STAKING_PERIODS = {
    MONTHLY: 30 * 24 * 60 * 60,  // 30 gün
    YEARLY: 365 * 24 * 60 * 60   // 365 gün
};

// Period Label helper
export const getPeriodLabel = (duration) => {
    switch (duration) {
        case STAKING_PERIODS.MONTHLY:
            return "30 Days";
        case STAKING_PERIODS.YEARLY:
            return "365 Days";
        default:
            return `${duration / (24 * 60 * 60)} Days`;
    }
};
    export const TOKEN_CATEGORIES = {
      DEFI: 'DeFi',
      MEME: 'Meme',
      STABLECOIN: 'Stabil',
      FAN_TOKEN: 'Fan Token',
      AI: 'AI',
      RWA: 'RWA',
      METAVERSE: 'Metaverse'
    };

    export const SUPPORTED_TOKENS = {
      BNB: {
        symbol: 'BNB',
        name: 'Binance Coin',
        address: '0x0000000000000000000000000000000000000000',
        decimals: 18,
        logo: 'https://cryptologos.cc/logos/bnb-bnb-logo.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '0.01',
          [STAKING_PERIODS.YEARLY]: '0.01'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 30,
          [STAKING_PERIODS.YEARLY]: 50
        },
        isNative: true,
        category: TOKEN_CATEGORIES.DEFI
      },
      USDT: {
        symbol: 'USDT',
        name: 'Tether USD',
        address: '0x55d398326f99059fF775485246999027B3197955',
        decimals: 18,
        logo: 'https://cryptologos.cc/logos/tether-usdt-logo.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '10',
          [STAKING_PERIODS.YEARLY]: '10'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.STABLECOIN
      },

    CAKE: {
    symbol: 'CAKE',
    name: 'PancakeSwap Token',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '50',
      [STAKING_PERIODS.YEARLY]: '50'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '10000',
      [STAKING_PERIODS.YEARLY]: '10000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 35,
      [STAKING_PERIODS.YEARLY]: 55
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    BTCB: {
    symbol: 'BTCB',
    name: 'Binance BTC',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '0.01',
      [STAKING_PERIODS.YEARLY]: '0.01'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '50',
      [STAKING_PERIODS.YEARLY]: '50'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 30,
      [STAKING_PERIODS.YEARLY]: 45
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    ETH: {
    symbol: 'ETH',
    name: 'Binance-Peg Ethereum',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    decimals: 18,
    logo: 'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '0.1',
      [STAKING_PERIODS.YEARLY]: '0.1'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100',
      [STAKING_PERIODS.YEARLY]: '100'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 35,
      [STAKING_PERIODS.YEARLY]: 50
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    XRP: {
    symbol: 'XRP',
    name: 'Binance-Peg XRP',
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    decimals: 18,
    logo: 'https://seeklogo.com/images/X/xrp-xrp-logo-CBBF77A5CF-seeklogo.com.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '50',
      [STAKING_PERIODS.YEARLY]: '50'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 35,
      [STAKING_PERIODS.YEARLY]: 50
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    ADA: {
    symbol: 'ADA',
    name: 'Binance-Peg Cardano',
    address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    decimals: 18,
    logo: 'https://cdn4.iconfinder.com/data/icons/crypto-currency-and-coin-2/256/cardano_ada-512.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    DOT: {
    symbol: 'DOT',
    name: 'Binance-Peg Polkadot',
    address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/polkadot-new-dot-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    MATIC: {
    symbol: 'MATIC',
    name: 'Binance-Peg Polygon',
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    decimals: 18,
    logo: 'https://altcoinsbox.com/wp-content/uploads/2023/03/matic-logo.webp',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    LINK: {
    symbol: 'LINK',
    name: 'Chainlink Token',
    address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/chainlink-link-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    DAI: {
    symbol: 'DAI',
    name: 'Binance-Peg Dai Token',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    FIL: {
    symbol: 'FIL',
    name: 'Binance-Peg Filecoin',
    address: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    decimals: 18,
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiKP3I2-vuA9ESU0osvABOKPRrMpOlIOPQWQ&s',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    ATOM: {
    symbol: 'ATOM',
    name: 'Binance-Peg Cosmos',
    address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    decimals: 18,
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCUj0V-GAIWh8MK0gdjPvWJeDZeAuiRo_Mkw&s',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    UNI: {
    symbol: 'UNI',
    name: 'Binance-Peg Uniswap',
    address: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    DOGE: {
    symbol: 'DOGE',
    name: 'Binance-Peg Dogecoin',
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    decimals: 18,
    logo: 'https://upload.wikimedia.org/wikipedia/tr/thumb/d/db/Dogecoin-logo.png/1200px-Dogecoin-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.MEME
  },
    SHIB: {
    symbol: 'SHIB',
    name: 'SHIBA INU',
    address: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    decimals: 18,
    logo: 'https://upload.wikimedia.org/wikipedia/tr/5/51/Shiba_coin_logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.MEME
  },
    AVAX: {
    symbol: 'AVAX',
    name: 'Binance-Peg Avalanche',
    address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    TRX: {
    symbol: 'TRX',
    name: 'TRON',
    address: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    decimals: 18,
    logo: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/tron-trx-7152138-5795294.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
    SOL: {
    symbol: 'SOL',
    name: 'Binance-Peg Solana',
    address: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/solana-sol-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 30
    },
    isNative: false,
       category: TOKEN_CATEGORIES.DEFI
  },
   PYTH: {
      symbol: 'PYTH',
      name: 'Pyth Network',
      address: '0x22fC5A29775C76D4bA6b3581EA68BDAD41299B5B',
      decimals: 18,
      logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqKCr-nVE1zvykjgdQOm5yhHGN_g6wTEmgtw&s',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
      category: TOKEN_CATEGORIES.DEFI
    },
    BLUR: {
      symbol: 'BLUR',
      name: 'Blur Token',
      address: '0x0238228d4E30094762574E87359AbD1eb95C506d',
      decimals: 18,
      logo: 'https://assets.coingecko.com/coins/images/28453/large/blur.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.DEFI
    },
    PIXEL: {
      symbol: 'PIXEL',
      name: 'Pixels',
      address: '0x89C465885850a96E42A32E8E03E2CD26C17E64eF',
      decimals: 18,
      logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/29335.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.METAVERSE
    },
    STRK: {
      symbol: 'STRK',
      name: 'Starknet',
      address: '0x8Ee3E98DCcC79A86Af5B5eE61e887CCeD787dF4D',
      decimals: 18,
      logo: 'https://cryptologos.cc/logos/starknet-token-strk-logo.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.DEFI
    },
    MEME: {
      symbol: 'MEME',
      name: 'Memecoin',
      address: '0xE61C27756B1F6FAb942aF9F95892A4905Bd7D7b1',
      decimals: 18,
      logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/28301.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.MEME
    },
    BONK: {
      symbol: 'BONK',
      name: 'Bonk',
      address: '0xC9E0345D4aA4D4DeC36Ad7E78b38Cf6C8B4ca907',
      decimals: 18,
      logo: 'https://assets.coingecko.com/coins/images/28600/large/bonk.jpg',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.MEME
    },
    TIA: {
      symbol: 'TIA',
      name: 'Celestia',
      address: '0x6Fc4563460D2B97B6F117170723E5689625F4160',
      decimals: 18,
      logo: 'https://assets.coingecko.com/coins/images/31967/large/tia.jpg',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.DEFI
    },
    ORDI: {
      symbol: 'ORDI',
      name: 'ORDI',
      address: '0x43Df7fB1Af0B37841Aa26D1945C9fEd7f55390B8',
      decimals: 18,
      logo: 'https://cryptologos.cc/logos/ordi-ordi-logo.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.DEFI
    },
    JUP: {
      symbol: 'JUP',
      name: 'Jupiter',
      address: '0x2562f94E90dE6D9eb4fB6B3b8Eab56b15Aa4FC72',
      decimals: 18,
      logo: 'https://cryptologos.cc/logos/jupiter-ag-jup-logo.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.DEFI
    },
    AIDOGE: {
      symbol: 'AIDOGE',
      name: 'ArbDoge AI',
      address: '0x09E18590E8f76b6Cf471b3cd75fE1A1a9D2B2c2A',
      decimals: 18,
      logo: 'https://assets.coingecko.com/coins/images/29852/large/photo_2023-04-18_14-25-28.jpg',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '10',
        [STAKING_PERIODS.YEARLY]: '10'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '100000',
        [STAKING_PERIODS.YEARLY]: '100000'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 20,
        [STAKING_PERIODS.YEARLY]: 30
      },
      isNative: false,
       category: TOKEN_CATEGORIES.MEME
    },
  WETH: {
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '0.1',
      [STAKING_PERIODS.YEARLY]: '0.1'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100',
      [STAKING_PERIODS.YEARLY]: '100'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 25,
      [STAKING_PERIODS.YEARLY]: 40
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  PEPE: {
    symbol: 'PEPE',
    name: 'Pepe Token',
    address: '0x25d887Ce7a35172C62FeBFd67a1856F20FaEbB00',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '10000000',
      [STAKING_PERIODS.YEARLY]: '10000000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 30,
      [STAKING_PERIODS.YEARLY]: 45
    },
    isNative: false,
     category: TOKEN_CATEGORIES.MEME
  },
  CYBER: {
    symbol: 'CYBER',
    name: 'CyberConnect',
    address: '0x14778860E937f509e651192a90589dE711152355',
    decimals: 18,
    logo: 'https://www.coinlore.com/img/cyberconnect.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '50000',
      [STAKING_PERIODS.YEARLY]: '50000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 20,
      [STAKING_PERIODS.YEARLY]: 35
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  SEI: {
    symbol: 'SEI',
    name: 'Sei Network',
    address: '0xF236714D6E072039518F13C0143B34c7F2E88720',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/sei-sei-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 25,
      [STAKING_PERIODS.YEARLY]: 40
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  GALA: {
    symbol: 'GALA',
    name: 'Gala Games',
    address: '0x7dDEE176F665cD201F93eEDE625770E2fD911990',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/12493/large/GALA-COINGECKO.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '100',
      [STAKING_PERIODS.YEARLY]: '100'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '1000000',
      [STAKING_PERIODS.YEARLY]: '1000000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 22,
      [STAKING_PERIODS.YEARLY]: 38
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  DYDX: {
    symbol: 'DYDX',
    name: 'dYdX',
    address: '0x0A2046C7cA18B4786E4B4E44C302cB927FC1C38A',
    decimals: 18,
    logo: 'https://seeklogo.com/images/D/dydx-exchange-logo-81BA4390A2-seeklogo.com.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '50000',
      [STAKING_PERIODS.YEARLY]: '50000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 28,
      [STAKING_PERIODS.YEARLY]: 42
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  WLD: {
    symbol: 'WLD',
    name: 'Worldcoin',
    address: '0x163f182C32d24A09d91EB75820cDe9FD5832b329',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/31069/large/worldcoin.jpeg',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 24,
      [STAKING_PERIODS.YEARLY]: 36
    },
    isNative: false,
     category: TOKEN_CATEGORIES.AI
  },
  IMX: {
    symbol: 'IMX',
    name: 'Immutable X',
    address: '0x9B67d3467e20aC66981E4c9C72F53E118c08C208',
    decimals: 18,
    logo: 'https://cryptologos.cc/logos/immutable-x-imx-logo.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '100000',
      [STAKING_PERIODS.YEARLY]: '100000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 26,
      [STAKING_PERIODS.YEARLY]: 40
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  ARB: {
    symbol: 'ARB',
    name: 'Arbitrum',
    address: '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '50000',
      [STAKING_PERIODS.YEARLY]: '50000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 25,
      [STAKING_PERIODS.YEARLY]: 38
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
  OP: {
    symbol: 'OP',
    name: 'Optimism',
    address: '0x3Ee7094DADda15810F191DD6AcF7E4FFa37571e4',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/25244/large/Optimism.png',
    minAmount: {
      [STAKING_PERIODS.MONTHLY]: '10',
      [STAKING_PERIODS.YEARLY]: '10'
    },
    maxAmount: {
      [STAKING_PERIODS.MONTHLY]: '50000',
      [STAKING_PERIODS.YEARLY]: '50000'
    },
    apr: {
      [STAKING_PERIODS.MONTHLY]: 24,
      [STAKING_PERIODS.YEARLY]: 36
    },
    isNative: false,
     category: TOKEN_CATEGORIES.DEFI
  },
      LAZIO: {
      symbol: 'LAZIO',
      name: 'S.S. Lazio Fan Token',
      address: '0x77d547256a2cd95f32f67ae0313e450ac200648d',
      decimals: 18,
      logo: 'https://public.nftstatic.com/static/fantoken/df010f2fd88d4d3fb7961f923336bd2b.png',
      minAmount: {
        [STAKING_PERIODS.MONTHLY]: '20',
        [STAKING_PERIODS.YEARLY]: '20'
      },
      maxAmount: {
        [STAKING_PERIODS.MONTHLY]: '7500',
        [STAKING_PERIODS.YEARLY]: '7500'
      },
      apr: {
        [STAKING_PERIODS.MONTHLY]: 40,
        [STAKING_PERIODS.YEARLY]: 50
      },
      isNative: false,
      category: TOKEN_CATEGORIES.FAN_TOKEN
      },
       PORTO: {
      symbol: 'PORTO',
        name: 'FC Porto Fan Token',
        address: '0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/14052.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '20',
          [STAKING_PERIODS.YEARLY]: '20'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '7500',
          [STAKING_PERIODS.YEARLY]: '7500'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 40,
          [STAKING_PERIODS.YEARLY]: 50
        },
        isNative: false,
        category: TOKEN_CATEGORIES.FAN_TOKEN
      },
      SANTOS: {
        symbol: 'SANTOS',
          name: 'Santos FC Fan Token',
          address: '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
          decimals: 18,
          logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/15248.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '20',
            [STAKING_PERIODS.YEARLY]: '20'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '7500',
            [STAKING_PERIODS.YEARLY]: '7500'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 40,
            [STAKING_PERIODS.YEARLY]: 50
          },
          isNative: false,
          category: TOKEN_CATEGORIES.FAN_TOKEN
        },
      ATM: {
        symbol: 'ATM',
        name: 'Atletico de Madrid Fan Token',
        address: '0x4c3E460b8e8285DE57c8B1B2B688847B995B71D6',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5227.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '20',
          [STAKING_PERIODS.YEARLY]: '20'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '7500',
          [STAKING_PERIODS.YEARLY]: '7500'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 40,
          [STAKING_PERIODS.YEARLY]: 50
        },
        isNative: false,
        category: TOKEN_CATEGORIES.FAN_TOKEN
      },
      ASR: {
          symbol: 'ASR',
          name: 'AS Roma Fan Token',
          address: '0x80D5f92C2c8C682070C95495313dDB680B267320',
          decimals: 18,
          logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5229.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '20',
            [STAKING_PERIODS.YEARLY]: '20'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '7500',
            [STAKING_PERIODS.YEARLY]: '7500'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 40,
            [STAKING_PERIODS.YEARLY]: 50
          },
          isNative: false,
          category: TOKEN_CATEGORIES.FAN_TOKEN
        },

        BAR: {
          symbol: 'BAR',
          name: 'FC Barcelona Fan Token',
          address: '0x8F7dB2BBaBa9E72978D7247fA10Bd18e38B7cd8B',
          decimals: 18,
          logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5225.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '10',
            [STAKING_PERIODS.YEARLY]: '10'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '60000',
            [STAKING_PERIODS.YEARLY]: '60000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 20,
            [STAKING_PERIODS.YEARLY]: 35
          },
          isNative: false,
          category: TOKEN_CATEGORIES.FAN_TOKEN
        },

        PSG: {
          symbol: 'PSG',
          name: 'Paris Saint-Germain Fan Token',
          address: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
          decimals: 18,
          logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5226.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '10',
            [STAKING_PERIODS.YEARLY]: '10'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '55000',
            [STAKING_PERIODS.YEARLY]: '55000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 18,
            [STAKING_PERIODS.YEARLY]: 32
          },
          isNative: false,
          category: TOKEN_CATEGORIES.FAN_TOKEN
        },

        JUV: {
          symbol: 'JUV',
          name: 'Juventus Fan Token',
          address: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/10060/large/Juve-10.png?1696510090',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '10',
            [STAKING_PERIODS.YEARLY]: '10'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '50000',
            [STAKING_PERIODS.YEARLY]: '50000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 15,
            [STAKING_PERIODS.YEARLY]: 28
          },
          isNative: false,
          category: TOKEN_CATEGORIES.FAN_TOKEN
        },
      AGIX: {
        symbol: 'AGIX',
        name: 'SingularityNET',
        address: '0x5B7533812759B45C2B44C19e320ba2cD2681b542',
        decimals: 18,
        logo: 'https://cryptologos.cc/logos/singularitynet-agix-logo.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 15,
          [STAKING_PERIODS.YEARLY]: 25
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      OCEAN: {
        symbol: 'OCEAN',
        name: 'Ocean Protocol',
        address: '0xDCe07662CA8EbC241316a15B611c89711414Dd1a',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 18,
          [STAKING_PERIODS.YEARLY]: 28
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      // Metaverse Tokens
      SAND: {
        symbol: 'SAND',
        name: 'The Sandbox',
        address: '0x67b725d7e342d7B611fa85e859Df9697D9378B2e',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 12,
          [STAKING_PERIODS.YEARLY]: 24
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      MANA: {
        symbol: 'MANA',
        name: 'Decentraland',
        address: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '50',
          [STAKING_PERIODS.YEARLY]: '50'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '75000',
          [STAKING_PERIODS.YEARLY]: '75000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 15,
          [STAKING_PERIODS.YEARLY]: 25
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      // Meme Tokens
      FLOKI: {
        symbol: 'FLOKI',
        name: 'Floki Inu',
        address: '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/16746/large/PNG_image.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000000',
          [STAKING_PERIODS.YEARLY]: '1000000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000000',
          [STAKING_PERIODS.YEARLY]: '100000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.MEME
      },

      // RWA (Real World Asset) Tokens
      GOLD: {
        symbol: 'GOLD',
        name: 'Paxos Gold',
        address: '0x7950865a9140cB519342433146Ed5b40c6F210f7',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/9519/large/paxg.PNG',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '0.01',
          [STAKING_PERIODS.YEARLY]: '0.01'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 8,
          [STAKING_PERIODS.YEARLY]: 15
        },
        isNative: false,
        category: TOKEN_CATEGORIES.RWA
      },
    
        // DeFi Tokens
        GRT: {
          symbol: 'GRT',
          name: 'The Graph',
          address: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '100',
            [STAKING_PERIODS.YEARLY]: '100'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '50000',
            [STAKING_PERIODS.YEARLY]: '50000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 15,
            [STAKING_PERIODS.YEARLY]: 25
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        FTM: {
          symbol: 'FTM',
          name: 'Fantom',
          address: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/4001/large/Fantom_round.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '500',
            [STAKING_PERIODS.YEARLY]: '500'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '100000',
            [STAKING_PERIODS.YEARLY]: '100000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 18,
            [STAKING_PERIODS.YEARLY]: 28
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        NEAR: {
          symbol: 'NEAR',
          name: 'NEAR Protocol',
          address: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/10365/large/near.jpg',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '50',
            [STAKING_PERIODS.YEARLY]: '50'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '25000',
            [STAKING_PERIODS.YEARLY]: '25000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 20,
            [STAKING_PERIODS.YEARLY]: 30
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        INJ: {
          symbol: 'INJ',
          name: 'Injective',
          address: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/12882/large/Secondary_Symbol.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '10',
            [STAKING_PERIODS.YEARLY]: '10'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '5000',
            [STAKING_PERIODS.YEARLY]: '5000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 25,
            [STAKING_PERIODS.YEARLY]: 35
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        CRV: {
          symbol: 'CRV',
          name: 'Curve DAO Token',
          address: '0x8dA443F84fEA710266C8eB6bC34B71702d033613',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '100',
            [STAKING_PERIODS.YEARLY]: '100'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '50000',
            [STAKING_PERIODS.YEARLY]: '50000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 22,
            [STAKING_PERIODS.YEARLY]: 32
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        // AI Tokens
        ROSE: {
          symbol: 'ROSE',
          name: 'Oasis Network',
          address: '0x6B8C88f44A5C5C3F12bF7F0Ae3B7146e6FAe0474',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/13162/large/rose.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '500',
            [STAKING_PERIODS.YEARLY]: '500'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '100000',
            [STAKING_PERIODS.YEARLY]: '100000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 16,
            [STAKING_PERIODS.YEARLY]: 26
          },
          isNative: false,
          category: TOKEN_CATEGORIES.AI
        },

        FET: {
          symbol: 'FET',
          name: 'Fetch.ai',
          address: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '1000',
            [STAKING_PERIODS.YEARLY]: '1000'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '200000',
            [STAKING_PERIODS.YEARLY]: '200000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 18,
            [STAKING_PERIODS.YEARLY]: 28
          },
          isNative: false,
          category: TOKEN_CATEGORIES.AI
        },

        // Metaverse & Gaming Tokens
        AXS: {
          symbol: 'AXS',
          name: 'Axie Infinity',
          address: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '10',
            [STAKING_PERIODS.YEARLY]: '10'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '5000',
            [STAKING_PERIODS.YEARLY]: '5000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 25,
            [STAKING_PERIODS.YEARLY]: 35
          },
          isNative: false,
          category: TOKEN_CATEGORIES.METAVERSE
        },

        ILV: {
          symbol: 'ILV',
          name: 'Illuvium',
          address: '0x7C8161545717a334F3196F1b314AF93C2bA3cF40',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/14468/large/ILV.JPG',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '1',
            [STAKING_PERIODS.YEARLY]: '1'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '1000',
            [STAKING_PERIODS.YEARLY]: '1000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 30,
            [STAKING_PERIODS.YEARLY]: 45
          },
          isNative: false,
          category: TOKEN_CATEGORIES.METAVERSE
        },

        // DeFi Tokens (Additional)
        AAVE: {
          symbol: 'AAVE',
          name: 'Aave',
          address: '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '0.5',
            [STAKING_PERIODS.YEARLY]: '0.5'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '500',
            [STAKING_PERIODS.YEARLY]: '500'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 20,
            [STAKING_PERIODS.YEARLY]: 30
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        COMP: {
          symbol: 'COMP',
          name: 'Compound',
          address: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/10775/large/COMP.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '1',
            [STAKING_PERIODS.YEARLY]: '1'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '1000',
            [STAKING_PERIODS.YEARLY]: '1000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 15,
            [STAKING_PERIODS.YEARLY]: 25
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        SUSHI: {
          symbol: 'SUSHI',
          name: 'SushiSwap',
          address: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '100',
            [STAKING_PERIODS.YEARLY]: '100'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '50000',
            [STAKING_PERIODS.YEARLY]: '50000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 18,
            [STAKING_PERIODS.YEARLY]: 28
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        // Layer 2 & Scaling Solutions
        MATIC: {
          symbol: 'MATIC',
          name: 'Polygon',
          address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '100',
            [STAKING_PERIODS.YEARLY]: '100'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '50000',
            [STAKING_PERIODS.YEARLY]: '50000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 22,
            [STAKING_PERIODS.YEARLY]: 32
          },
          isNative: false,
          category: TOKEN_CATEGORIES.DEFI
        },

        // Gaming Tokens
        ENJ: {
          symbol: 'ENJ',
          name: 'Enjin Coin',
          address: '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
          decimals: 18,
          logo: 'https://assets.coingecko.com/coins/images/1102/large/enjin-coin-logo.png',
          minAmount: {
            [STAKING_PERIODS.MONTHLY]: '500',
            [STAKING_PERIODS.YEARLY]: '500'
          },
          maxAmount: {
            [STAKING_PERIODS.MONTHLY]: '100000',
            [STAKING_PERIODS.YEARLY]: '100000'
          },
          apr: {
            [STAKING_PERIODS.MONTHLY]: 15,
            [STAKING_PERIODS.YEARLY]: 25
          },
          isNative: false,
          category: TOKEN_CATEGORIES.METAVERSE
        },
      SNX: {
        symbol: 'SNX',
        name: 'Synthetix',
        address: '0x9Ac983826058b8a9C7Aa1C9171441191232E8404',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '10',
          [STAKING_PERIODS.YEARLY]: '10'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      YFI: {
        symbol: 'YFI',
        name: 'yearn.finance',
        address: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '0.01',
          [STAKING_PERIODS.YEARLY]: '0.01'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '10',
          [STAKING_PERIODS.YEARLY]: '10'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 18,
          [STAKING_PERIODS.YEARLY]: 28
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ALPHA: {
        symbol: 'ALPHA',
        name: 'Alpha Finance',
        address: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12738/large/AlphaToken_256x256.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      RDNT: {
        symbol: 'RDNT',
        name: 'Radiant Capital',
        address: '0xf7DE7E8A6bd59ED41a4b5fe50278b3B7f31384dF',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/21106.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 30,
          [STAKING_PERIODS.YEARLY]: 45
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ANKR: {
        symbol: 'ANKR',
        name: 'Ankr Network',
        address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '500000',
          [STAKING_PERIODS.YEARLY]: '500000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ZIL: {
        symbol: 'ZIL',
        name: 'Zilliqa',
        address: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/2687/large/Zilliqa-logo.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 19,
          [STAKING_PERIODS.YEARLY]: 29
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      // Metaverse & Gaming Tokens
      ALICE: {
        symbol: 'ALICE',
        name: 'My Neighbor Alice',
        address: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/14375/large/alice_logo.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '50',
          [STAKING_PERIODS.YEARLY]: '50'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '10000',
          [STAKING_PERIODS.YEARLY]: '10000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      CHR: {
        symbol: 'CHR',
        name: 'Chromia',
        address: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/5000/large/Chromia.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      HIGH: {
        symbol: 'HIGH',
        name: 'Highstreet',
        address: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/18973/large/logosq200200Coingecko.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      // AI Tokens
      JASMY: {
        symbol: 'JASMY',
        name: 'JasmyCoin',
        address: '0x15669CF161946C09A7B0b3B15A3E0fc62B761618',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13876/large/JASMY200x200.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000000',
          [STAKING_PERIODS.YEARLY]: '1000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 15,
          [STAKING_PERIODS.YEARLY]: 25
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      // Additional DeFi Tokens
      BIFI: {
        symbol: 'BIFI',
        name: 'Beefy Finance',
        address: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12704/large/token.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '0.1',
          [STAKING_PERIODS.YEARLY]: '0.1'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50',
          [STAKING_PERIODS.YEARLY]: '50'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 35,
          [STAKING_PERIODS.YEARLY]: 45
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      CHESS: {
        symbol: 'CHESS',
        name: 'Tranchess',
        address: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/10974.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '20000',
          [STAKING_PERIODS.YEARLY]: '20000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },
      LEVER: {
        symbol: 'LEVER',
        name: 'LeverFi',
        address: '0x2D7a47908d907116F246A9CdF36976E916dF5DF2',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/20873.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 42
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      HOOK: {
        symbol: 'HOOK',
        name: 'Hooked Protocol',
        address: '0xa260E12d2B924cb899AE80BB58123ac3fEE1E2F0',
        decimals: 18,
        logo: 'https://img.cryptorank.io/coins/hooked_protocol1708525742892.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ACH: {
        symbol: 'ACH',
        name: 'Alchemy Pay',
        address: '0xBc7d6B50616989655AfD682fb42743507003056D',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12390/large/ACH_%281%29.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000000',
          [STAKING_PERIODS.YEARLY]: '1000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      LOOM: {
        symbol: 'LOOM',
        name: 'Loom Network',
        address: '0x42476F744292107e34519F9c357927074Ea3F75D',
        decimals: 18,
        logo: 'https://static-00.iconduck.com/assets.00/loom-network-cryptocurrency-icon-2048x2048-i3jvhdx6.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 18,
          [STAKING_PERIODS.YEARLY]: 28
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      // AI & Computing Tokens
      PHA: {
        symbol: 'PHA',
        name: 'Phala Network',
        address: '0x0112e557d400474717056C4e6D40eDD846F38351',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12451/large/phala.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      ARKM: {
        symbol: 'ARKM',
        name: 'Arkham',
        address: '0x6E2a43be0B1815EFb34C0385aE5636C8Ec3F2C3F',
        decimals: 18,
        logo: 'https://s3.coinmarketcap.com/static-gravity/image/08eab8e3e1e143899030c2d01cf35992.jpeg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      // Metaverse & Gaming
      MAGIC: {
        symbol: 'MAGIC',
        name: 'Magic',
        address: '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/18623/large/magic.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '50',
          [STAKING_PERIODS.YEARLY]: '50'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '10000',
          [STAKING_PERIODS.YEARLY]: '10000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 30,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      SLP: {
        symbol: 'SLP',
        name: 'Smooth Love Potion',
        address: '0x070a08BeEF8d36734dD67A491202fF35a6A16d97',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/10366/large/SLP.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000000',
          [STAKING_PERIODS.YEARLY]: '1000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      TLM: {
        symbol: 'TLM',
        name: 'Alien Worlds',
        address: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
        decimals: 18,
        logo: 'https://suncrypto.in/icons/TLM.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 24,
          [STAKING_PERIODS.YEARLY]: 34
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      // More DeFi Tokens
      WING: {
        symbol: 'WING',
        name: 'Wing Finance',
        address: '0x3CB7378565245D9e9d9c4f53E07608A7a82Af1B9',
        decimals: 18,
        logo: 'https://pbs.twimg.com/profile_images/1610159357827899394/_AbC7-K7_400x400.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '10',
          [STAKING_PERIODS.YEARLY]: '10'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      PENDLE: {
        symbol: 'PENDLE',
        name: 'Pendle',
        address: '0x808507121B80c02388fAd14726482e061B8da827',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/15069/large/Pendle_Logo_Normal-03.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '50',
          [STAKING_PERIODS.YEARLY]: '50'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '10000',
          [STAKING_PERIODS.YEARLY]: '10000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      STPT: {
        symbol: 'STPT',
        name: 'Standard Tokenization Protocol',
        address: '0x7C20818A7CFb7Ee4790f6C6D7e7AB9EEe10326A4',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/8713/large/STP.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },
      RUNE: {
        symbol: 'RUNE',
        name: 'THORChain',
        address: '0x3155BA85D5F96b2d030a4966AF206230e46849cb',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/6595/large/Rune200x200.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '10',
          [STAKING_PERIODS.YEARLY]: '10'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      DEGO: {
        symbol: 'DEGO',
        name: 'Dego Finance',
        address: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12503/large/c185FKx.png?1696512318',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '20000',
          [STAKING_PERIODS.YEARLY]: '20000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      QUICK: {
        symbol: 'QUICK',
        name: 'Quickswap',
        address: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13970/large/1_pOU6pBMEmiL-ZJVb0CYRjQ.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1',
          [STAKING_PERIODS.YEARLY]: '1'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 30,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      MLN: {
        symbol: 'MLN',
        name: 'Enzyme',
        address: '0xeD7B8606270295d1912c6d3D4e34B75f16B6cBaF',
        decimals: 18,
        logo: 'https://cryptologos.cc/logos/enzyme-mln-logo.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5',
          [STAKING_PERIODS.YEARLY]: '5'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      // Metaverse & NFT Tokens
      RARE: {
        symbol: 'RARE',
        name: 'SuperRare',
        address: '0xE34E13F6D3167687F4B5D989BAe21b61F02cCF0B',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/17753/large/RARE.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '25000',
          [STAKING_PERIODS.YEARLY]: '25000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 24,
          [STAKING_PERIODS.YEARLY]: 34
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      SUPER: {
        symbol: 'SUPER',
        name: 'SuperFarm',
        address: '0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/14040/large/6YPdWn6.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 26,
          [STAKING_PERIODS.YEARLY]: 36
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },

      // AI & Infrastructure Tokens
      KEY: {
        symbol: 'KEY',
        name: 'SelfKey',
        address: '0x4CD0c43B0D53BC318cc5342B77EB6f124E47f526',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/2034/large/selfkey.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '10000',
          [STAKING_PERIODS.YEARLY]: '10000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '2000000',
          [STAKING_PERIODS.YEARLY]: '2000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      // More DeFi Tokens
      POND: {
        symbol: 'POND',
        name: 'Marlin',
        address: '0x57B946008913B82E4dF85f501cbAeD910e58D26C',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/8903/large/POND_200x200.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '500000',
          [STAKING_PERIODS.YEARLY]: '500000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 18,
          [STAKING_PERIODS.YEARLY]: 28
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ALPACA: {
        symbol: 'ALPACA',
        name: 'Alpaca Finance',
        address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
        decimals: 18,
        logo: 'https://pbs.twimg.com/profile_images/1481749291379081217/KGzK2UQS_400x400.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      POLS: {
        symbol: 'POLS',
        name: 'Polkastarter',
        address: '0x7e624FA0E1c4AbFD309cC15719b7E2580887f570',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12648/large/polkastarter.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '20000',
          [STAKING_PERIODS.YEARLY]: '20000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      UFT: {
        symbol: 'UFT',
        name: 'UniLend Finance',
        address: '0x2645d5f59D952ef2317C8F9A49e88A3E7D5C4D8C',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12819/large/UniLend_Finance_logo_PNG.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 24,
          [STAKING_PERIODS.YEARLY]: 34
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      SPARTA: {
        symbol: 'SPARTA',
        name: 'Spartan Protocol',
        address: '0x3910db0600eA925F63C36DdB1351aB6E2c6eb102',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6992.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },
      LIT: {
        symbol: 'LIT',
        name: 'Litentry',
        address: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13825/large/logo_200x200.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '25000',
          [STAKING_PERIODS.YEARLY]: '25000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 24,
          [STAKING_PERIODS.YEARLY]: 34
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      BAKE: {
        symbol: 'BAKE',
        name: 'BakeryToken',
        address: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12588/large/bakery.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 20,
          [STAKING_PERIODS.YEARLY]: 30
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      BADGER: {
        symbol: 'BADGER',
        name: 'Badger DAO',
        address: '0x753fbc5800a8C8e3Fb6DC6415810d627A387Dfc9',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5',
          [STAKING_PERIODS.YEARLY]: '5'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      ARPA: {
        symbol: 'ARPA',
        name: 'ARPA Chain',
        address: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/8506/large/9u0a23XY_400x400.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 22,
          [STAKING_PERIODS.YEARLY]: 32
        },
        isNative: false,
        category: TOKEN_CATEGORIES.AI
      },

      PNT: {
        symbol: 'PNT',
        name: 'pNetwork',
        address: '0x7a1da9F49224EF98389B071b8A3294D1Cc5e3e6A',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/11659/large/pNetwork.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '500',
          [STAKING_PERIODS.YEARLY]: '500'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '100000',
          [STAKING_PERIODS.YEARLY]: '100000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      BSW: {
        symbol: 'BSW',
        name: 'Biswap',
        address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/16845/large/biswap.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 30,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      EPX: {
        symbol: 'EPX',
        name: 'Ellipsis',
        address: '0xAf41054C1487b0e5E2B9250C0332eCBCe6CE9d71',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/25444/large/ellipsis-light_%281%29.png?1696524576',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '5000',
          [STAKING_PERIODS.YEARLY]: '5000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000000',
          [STAKING_PERIODS.YEARLY]: '1000000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 26,
          [STAKING_PERIODS.YEARLY]: 36
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      TORN: {
        symbol: 'TORN',
        name: 'Tornado Cash',
        address: '0x1bA8D3C4c219B124d351F603060663BD1bcd9bbF',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13496/large/ZINt8NSB_400x400.jpg',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1',
          [STAKING_PERIODS.YEARLY]: '1'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200',
          [STAKING_PERIODS.YEARLY]: '200'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 32,
          [STAKING_PERIODS.YEARLY]: 42
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      COMBO: {
        symbol: 'COMBO',
        name: 'Furucombo',
        address: '0xf6E06B54855efF198F8EE3A8562F065e6b75D891',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/13629/large/COMBO_token_ol.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '20000',
          [STAKING_PERIODS.YEARLY]: '20000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 24,
          [STAKING_PERIODS.YEARLY]: 34
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      QI: {
        symbol: 'QI',
        name: 'BENQI',
        address: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9288.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '1000',
          [STAKING_PERIODS.YEARLY]: '1000'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '200000',
          [STAKING_PERIODS.YEARLY]: '200000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 28,
          [STAKING_PERIODS.YEARLY]: 38
        },
        isNative: false,
        category: TOKEN_CATEGORIES.DEFI
      },

      WOOP: {
        symbol: 'WOOP',
        name: 'Woonkly Power',
        address: '0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
        decimals: 18,
        logo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/8937.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '50000',
          [STAKING_PERIODS.YEARLY]: '50000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 35
        },
        isNative: false,
        category: TOKEN_CATEGORIES.METAVERSE
      },
      FDUSD: {
        symbol: 'FDUSD',
        name: 'First Digital USD',
        address: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
        decimals: 18,
        logo: 'https://digitalcoinprice.com/generated-assets/assets/images/coins/200x200/first-digital-usd.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '250000',
          [STAKING_PERIODS.YEARLY]: '250000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.STABLECOIN
      },

      TUSD: {
        symbol: 'TUSD',
        name: 'TrueUSD',
        address: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/3449/large/tusd.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '250000',
          [STAKING_PERIODS.YEARLY]: '250000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.STABLECOIN
      },

      HAY: {
        symbol: 'HAY',
        name: 'Hay Destablecoin',
        address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
        decimals: 18,
        logo: 'https://d23exngyjlavgo.cloudfront.net/0x38_0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '250000',
          [STAKING_PERIODS.YEARLY]: '250000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.STABLECOIN
      },

      USTC: {
        symbol: 'USTC',
        name: 'TerraClassicUSD',
        address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
        decimals: 18,
        logo: 'https://assets.coingecko.com/coins/images/12681/large/UST.png',
        minAmount: {
          [STAKING_PERIODS.MONTHLY]: '100',
          [STAKING_PERIODS.YEARLY]: '100'
        },
        maxAmount: {
          [STAKING_PERIODS.MONTHLY]: '250000',
          [STAKING_PERIODS.YEARLY]: '250000'
        },
        apr: {
          [STAKING_PERIODS.MONTHLY]: 25,
          [STAKING_PERIODS.YEARLY]: 40
        },
        isNative: false,
        category: TOKEN_CATEGORIES.STABLECOIN
      },

    

   
  
    
      
  };
// Helper functions
export const getStakingOptions = () => {
  return [
      { days: STAKING_PERIODS.MONTHLY, label: "30 Days" },
      { days: STAKING_PERIODS.YEARLY, label: "365 Days" }
  ];
};

// Helper functions
export const getTokenByAddress = (address) => {
  if (!address) return null;
  return Object.values(SUPPORTED_TOKENS).find(
      token => token.address.toLowerCase() === address.toLowerCase()
  );
};

export const getTokenConfig = (symbol) => {
  return SUPPORTED_TOKENS[symbol];
};

export const calculateRewards = (amount, apr, duration) => {
  if (!amount || !apr || !duration) return 0;
  const yearInSeconds = 365 * 24 * 60 * 60;
  return (parseFloat(amount) * parseFloat(apr) * parseFloat(duration)) / (yearInSeconds * 100);
};

export const validateStakeAmount = (amount, token, duration) => {
  const tokenConfig = SUPPORTED_TOKENS[token];
  if (!tokenConfig) return { isValid: false, error: 'Token not supported' };

  const numAmount = parseFloat(amount);
  const minAmount = parseFloat(tokenConfig.minAmount[duration]);
  const maxAmount = parseFloat(tokenConfig.maxAmount[duration]);

  if (isNaN(numAmount) || numAmount <= 0) {
      return { isValid: false, error: 'Invalid amount' };
  }

  if (numAmount < minAmount) {
      return { 
          isValid: false, 
          error: `Minimum stake amount is ${minAmount} ${token}` 
      };
  }

  if (numAmount > maxAmount) {
      return { 
          isValid: false, 
          error: `Maximum stake amount is ${maxAmount} ${token}` 
      };
  }

  return { isValid: true, error: null };
};

// Referral helper
export const isValidReferrer = (referrer, userAddress) => {
  if (!referrer) return false;
  if (!referrer.match(/^0x[a-fA-F0-9]{40}$/)) return false;
  if (referrer === userAddress) return false;
  if (referrer === '0x0000000000000000000000000000000000000000') return false;
  return true;
};

// Platform stats
export const PLATFORM_STATS = {
  initialTotalUsers: 17194,
  initialTotalStaked: 49620,
  initialTotalRewards: 12464973,
};