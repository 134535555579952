// TokenSelector/CategorySelector.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { TOKEN_CATEGORIES } from '../../web3/config';

const CategorySelector = ({ selectedCategory, onSelectCategory }) => {
  return (
    <div className="relative overflow-x-auto">
      <div className="flex gap-2 p-1">
        <CategoryButton
          isSelected={selectedCategory === 'all'}
          onClick={() => onSelectCategory('all')}
        >
          All Tokens
        </CategoryButton>
        {Object.entries(TOKEN_CATEGORIES).map(([key, value]) => (
          <CategoryButton
            key={key}
            isSelected={selectedCategory === value}
            onClick={() => onSelectCategory(value)}
          >
            {value}
          </CategoryButton>
        ))}
      </div>
    </div>
  );
};

const CategoryButton = ({ children, isSelected, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    className={`px-6 py-2 rounded-xl text-sm font-medium whitespace-nowrap transition-all duration-300
      ${isSelected
        ? 'bg-gradient-to-r from-bnb-yellow to-yellow-500 text-white shadow-lg shadow-yellow-500/20'
        : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
      }
    `}
  >
    {children}
  </motion.button>
);

export default CategorySelector;